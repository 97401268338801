import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gantt-chart-month-content',
  templateUrl: './gantt-chart-month-content.component.html',
  styleUrls: ['./gantt-chart-month-content.component.scss']
})
export class GanttChartMonthContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
