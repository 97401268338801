import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-gantt-row-title',
  templateUrl: './gantt-row-title.component.html',
  styleUrls: ['./gantt-row-title.component.scss']
})
export class GanttRowTitleComponent implements OnInit {

  @Input() rowId:string

  row$: Observable<any>

  constructor(private stateService: StateService) { }

  ngOnInit(): void {
    this.row$ = this.stateService.getRow(this.rowId);

  }

}
