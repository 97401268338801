import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GraphService } from 'src/app/core/services/graph.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent implements OnInit {

  @Output() userSelected:EventEmitter<any> = new EventEmitter()

  users:any
  filter:string
  p:number = 1;

  filterForm = new FormGroup({
    filterText: new FormControl('')
  })

  constructor(private graph:GraphService, private state: StateService) { }

  ngOnInit(): void {

    // Get data from the filter input
    this.filterForm.valueChanges.subscribe((res) => {
      this.filter = res.filterText
    })


    // Load users from NGRX
    this.state.getUsers()
    .subscribe((users) => {
      this.users = users
    })
  }

  // Emit the selected user to the parent component
  selected(event) {
    console.log(event)
    this.userSelected.emit(event)
  }

}
