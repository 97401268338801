
<app-welcome></app-welcome>
<div class="w-full max-w-7xl mx-auto mt-5 pt-4 z-30">
<button
  (click)="createActivity()"
  class="bg-blue-700 m-2 text-white text-xl w-full rounded-full p-4"
>
  Skapa nytt projekt
</button>
<div class="w-full flex mt-3">
  <div class="w-full rounded-xl p-2 bg-gray-100 shadow-xl border">
    <p class="font-semibold text-sm uppercase ml-1 text-blue-900 mb-2">
      Mina projekt
    </p>
    <app-activities [scope]="projectScope"></app-activities>
  </div>
</div>
</div>


