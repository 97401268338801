// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCFBUZHdEjnSMpIhy4pZWdDPOQm4OvnxRI",
    authDomain: "systepv2.firebaseapp.com",
    projectId: "systepv2",
    storageBucket: "systepv2.appspot.com",
    messagingSenderId: "28832271729",
    appId: "1:28832271729:web:407c8d6520303c2285fad0",
    measurementId: "G-9VXZN4XPSL"
  },
  collections: {
    projects: 'projects',
    users: 'users'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
