<div class="w-full p-4 bg-gray-50 rounded border">
  <h1 *ngIf="!id" class="text-gray-900 font-bold text-xl">
    Lägg till risk
  </h1>

  <h1 *ngIf="id" class="text-gray-900 font-bold text-xl">
    Redigera Risk
  </h1>

  <div class="w-full my-1">
    <label class="text-sm font-semibold text-gray-800" for=""
      >Beskrivning</label
    >
    <input
      class="w-full p-2 text-lg rounded border"
      placeholder="Beskriv risken"
      [(ngModel)]="desc"
      type="text"
    />
  </div>
  <div class="w-full my-1">
    <label class="text-sm font-semibold text-gray-800" for=""
      >Vad kan vi göra för att minimera risken</label
    >
    <input
      class="w-full p-2 text-lg rounded border"
      placeholder="Vad gör vi för att minimera ovan risk?"
      [(ngModel)]="mitigation"
      type="text"
    />
  </div>
  <div class="w-full my-1">
    <label class="text-sm font-semibold text-gray-800" for=""
      >Hur stor är risken att detta inträffar?</label
    >
    <div class="flex">
      <div
        (click)="setRisk(1)"
        [class.bg-green-400]="risk === 1"
        [class.border]="risk === 1"
        [class.border-black]="risk === 1"
        class="rounded border-2 p-1 flex bg-green-200 text-xs h-5 w-5 mr-1 cursor-pointer"
      >
        <p class="self-center mx-auto font-bold">1</p>
      </div>
      <div
        (click)="setRisk(2)"
        [class.bg-blue-400]="risk === 2"
        [class.border]="risk === 2"
        [class.border-black]="risk === 2"
        class="rounded p-1 flex border-2 bg-blue-200 text-xs h-5 w-5 mr-1 cursor-pointer"
      >
        <p class="self-center mx-auto font-bold">2</p>
      </div>
      <div
        (click)="setRisk(3)"
        [class.bg-red-500]="risk === 3"
        [class.border]="risk === 3"
        [class.border-black]="risk === 3"
        class="rounded p-1 flex border-2 bg-red-300 text-xs h-5 w-5 mx-0.5 cursor-pointer"
      >
        <p class="self-center mx-auto font-bold">3</p>
      </div>
    </div>
  </div>
  <div class="w-full my-1">
    <label class="text-sm font-semibold text-gray-800" for=""
      >Hur stor blir konsekvensen om detta inträffar?</label
    >
    <div class="flex">
      <div
        (click)="setConsequence(1)"
        [class.bg-green-400]="consequence === 1"
        [class.border]="consequence === 1"
        [class.border-black]="consequence === 1"
        class="rounded p-1 flex border-2 bg-green-200 text-xs h-5 w-5 mr-1 cursor-pointer"
      >
        <p class="self-center mx-auto font-bold">1</p>
      </div>
      <div
        (click)="setConsequence(2)"
        [class.bg-blue-400]="consequence === 2"
        [class.border]="consequence === 2"
        [class.border-black]="consequence === 2"
        class="rounded p-1 flex border-2 bg-blue-200 text-xs h-5 w-5 mr-1 cursor-pointer"
      >
        <p class="self-center mx-auto font-bold">2</p>
      </div>
      <div
        (click)="setConsequence(3)"
        [class.bg-red-500]="consequence === 3"
        [class.border]="consequence === 3"
        [class.border-black]="consequence === 3"
        class="rounded p-1 flex border-2 bg-red-300 text-xs h-5 w-5 mx-0.5 cursor-pointer"
      >
        <p class="self-center mx-auto font-bold">3</p>
      </div>
    </div>
  </div>
  <div class="w-full mt-4">
    <button
      (click)="save()"
      [disabled]="!enableButton()"
      class="px-3 py-2   text-white text-xs font-bold uppercase rounded"
      [class.bg-gray-300]="!enableButton()"
      [class.text-gray-600]="!enableButton()"
      [class.bg-gray-800]="enableButton()"
    >
      Spara
    </button>
    <button
      (click)="delete()"
      class="px-3 py-2 ml-2 bg-red-400 hover:bg-red-700 text-white text-xs font-bold uppercase rounded"
    >
      Radera
    </button>
  </div>
</div>
