export const smallV3 = {
    regPyr: [3, 0],
    folderCreated: [3, 1], 
    confirmation: [9, 0],
    risk: [14, 0],
    closure: [32, 0]
} 

export const smallV2 = {
    regPyr: [2, 0],
    folderCreated: [2, 1], 
    confirmation: [8, 0],
    risk: [12, 0],
    closure: [35, 0]
} 

export const smallV1 = {
    regPyr: [2, 0],
    folderCreated: [2, 1], 
    confirmation: [8, 1],
    risk: [12, 0],
    closure: [35, 0]
} 

export const bigV1 = {
    regPyr: [2, 0],
    folderCreated: [2, 1], 
    confirmation: [8, 1],
    closure: [45, 0]
} 