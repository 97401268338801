<div class="w-full mx-auto rounded">
  <img
  class="w-full h-72 rounded"
  style="object-fit: cover"
  src="https://images.unsplash.com/photo-1504917595217-d4dc5ebe6122?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
  alt=""
/>
</div>
<div *ngIf="activity$ | async as activity" class="w-full max-w-7xl  rounded lg:pl-16 xl:pl-12 mx-auto">
<!--   <div class="margin-negative m-2 w-full">
    <p class="text-3xl uppercase font-semibold">{{ activity.name }}</p>
    <p class="text-lg leading-tight mb-4 text-blue-800">{{ activity.desc }}</p>
    <div class="w-full flex p-2 rounded bg-gray-100">
      <div class="w-1/2 flex flex-col">
        <div class="mb-1">
          <label class="text-xs font-semibold block" for="">Date:</label>
          <p>{{ activity.date }}</p>
        </div>
        <div class="mb-1">
          <label class="text-xs font-semibold block" for="">Location:</label>
          <p>{{ activity.location }}</p>
        </div>
        <div class="mb-1">
          <label class="text-xs font-semibold block" for="">Cost:</label>
          <p>{{ activity.cost }}SEK</p>
        </div>
      </div>
      <div class="w-1/2">
        <div class="mb-1">
          <label class="text-xs font-semibold block" for="">Last signup:</label>
          <p>{{ activity.date }}</p>
        </div>
        <div class="mb-1">
          <label class="text-xs font-semibold block" for=""
            >Maximum participants:</label
          >
          <p>{{ activity.location }}</p>
        </div>
      </div>
    </div>
  </div> -->
  <p class="text-3xl uppercase font-semibold my-3">{{ activity.name }}</p>
</div>

<div class="w-full max-w-7xl flex mx-auto mt-4 lg:pl-16 xl:pl-12">
<div class="lg:w-2/3 md:w-full p-2 shadow rounded">
<div *ngIf="blocks" class="w-full  p-2">
  <ng-container *ngFor="let block of blocks.blocks; let i = index">
    <app-block [block]="block" [blockIndex]="i"></app-block>
  </ng-container>
</div>
</div>  
<div class="rounded shadow ml-4 p-3 flex-col w-1/3">
  <div class="w-full flex">
    <div class="text-lg font-semibold inline-block mr-3 w-full">
      Uppdragsansvarig
      <p *ngIf="isParticipant()" class="text-xs font-normal">Du deltar redan i detta projekt!</p>
    </div>
      </div>
      <div class="w-full p-1">
        <app-participant *ngIf="manager" [participant]="manager"></app-participant>
      </div>
  <div class="w-full flex">
<div class="text-lg font-semibold inline-block mr-3 w-full">
  Projektdeltagare
  <p *ngIf="isParticipant()" class="text-xs font-normal">Du deltar redan i detta projekt!</p>
</div>




  </div>
  <div class="w-full">
    <button (click)="pickUser = !pickUser" class="rounded-full text-blue-800 p-1 font-semibold hover:bg-blue-100 mt-1 text-xs cursor-pointer">Lägg till deltagare</button>
    <div class="mt-2" *ngIf="pickUser">
      <app-user-picker (userSelected)="addParticipant($event)"></app-user-picker>
    </div>
    <div *ngIf="selectedUser" class="w-full flex flex-col mt-2 rounded shadow">
    <app-user-block *ngIf="selectedUser" [size]="'full'" [user]="selectedUser"></app-user-block>
    <form [formGroup]="userRoleForm" (ngSubmit)="onSubmit()">

      <div class="m-2">
        <input (click)="setRole()" type="checkbox" class="mr-1" formControlName="manager">
      <label for="checkboxk">Uppdragsansvarig</label>
      </div>
      
      
  
      <input class="w-full rounded p-1 text-sm border m-2" placeholder="Roll i projektet" type="text"  formControlName="role">
        <button type="submit" class="rounded-full text-white bg-blue-700 p-1 m-2 text-xs">Lägg till</button>

    </form>
    </div>
    
  </div>
  


  <app-participants [rm]="true" [id]="id"></app-participants>
</div>
</div>





<!-- <div class="w-full max-w-7xl mx-auto pt-5 flex lg:flex-row sm:flex-col">
    <div class="w-full max-w-3xl rounded p-3 mr-3">
        <div class="text-lg font-semibold inline-block mr-2">Activity description</div>
        <button (click)="saveStandard()" class="bg-blue-800 text-white p-2 rounded-full text-xs mb-2 font-semibold">Save changes</button>
        <div
        class="w-full max-w-3xl float-left pt-5 bg-white shadow rounded"
        id="editorjs"
        ></div>
    </div>
    <div class="rounded p-3 hidden md:flex flex-col">
        <div class="text-lg font-semibold inline-block mr-3">Participants</div>
        <button *ngIf="!isParticipant()" (click)="join()" class="bg-blue-800 text-white p-2 rounded-full text-xs mb-2 font-semibold">Join</button>
        <div *ngIf="isParticipant()">
            <p class="text-xs">You are already assigned to this activity.</p>
            <button (click)="cancel()" class="bg-red-500 text-white p-1 rounded-full inline-block text-xs mb-2 font-semibold">Cancel</button>
        </div>

        <app-participants [participants]="participants"></app-participants>
    </div>

</div>
<div class="rounded p-3 md:hidden">
    <div class="text-lg font-semibold inline-block mr-3">Participants</div>
    <button *ngIf="!isParticipant()" (click)="join()" class="bg-blue-800 text-white p-2 rounded-full text-xs mb-2 font-semibold">Join</button>
    <div *ngIf="isParticipant()">
        <p class="text-xs">You are already assigned to this activity.</p>
        <button (click)="cancel()" class="bg-red-500 text-white p-1 rounded-full inline-block text-xs mb-2 font-semibold">Cancel</button>
    </div>

    <app-participants [participants]="participants"></app-participants>
</div> -->




