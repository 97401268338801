import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';

import { Project } from '../reducers/dryfix.reducer'
import * as ActivitiesActions from '../actions/activities.actions'

import { FirebaseService } from '../../core/services/firebase.service'
import { Action } from '@ngrx/store';
//import { StateService } from '../../services/state.service';

@Injectable()
export class ActivitiesEffects {

  /*     @Effect()
  getData$ = this.fireBaseService.getCollectionStateChanges('testarea/dryfix/projects').pipe(
    mergeMap(actions => actions),
    map(action => {
        const data:any = action.payload.doc.data()
      return {
        type: `[DryfixData] ${action.type}`,
        payload: { 
          id: action.payload.doc.id, 
          ...data
        }
      };
    })
  ); */

  @Effect()
  queryBookings$ = this.actions$.pipe(
    ofType('[Activities] query activities'),
    map((action: ActivitiesActions.Query) => action),
    switchMap(data => {
     // this.stateService.setLoadingState(true)
      return this.firebaseService.getActivities$()
    }),
    mergeMap(actions => {
      //this.stateService.setLoadingState(false)
      return actions
    }),
    map(action => {
      const data: any = action.payload.doc.data()
      return {
        type: `[Activities] ${action.type}`,
        payload: {
          ...data,
          id: action.payload.doc.id
        }
      }
    })
  )






  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
   // private stateService: StateService
  ) { }
}