<div class="w-full">
<div class="w-full mt-20 flex max-w-7xl mx-auto">
  <div class="w-full mx-auto">
    <div class="w-full max-w-7xl mx-auto">
      <div
        class=" px-8 py-4 mx-auto bg-gray-100 rounded-lg shadow-md dark:bg-gray-800"
      >
        <div class="flex items-center justify-between mb-2">
          <div>
            <p class="text-5xl font-light text-gray-800 dark:text-gray-400"
            >Budgetering</p>
            <p class="text-xl font-light text-indigo-800 dark:text-gray-400 mb-4"
            >Som det borde vara</p>
          <p class="text-lg text-gray-700">Välkommen till Raksystems Dry-ITs nya budgeterings verktyg. Vi hoppas att detta verktyg kommer att hjälpa dig i ditt dagliga arbete att leverera norden bästa tjänster.</p>
          <p class="text-lg font-semibold mt-2 text-gray-700">Bäst i norden - Varje dag!</p>
          </div>
          <div>
              <img class="" src="../../../../assets/img/undraw_Calculator_0evy.svg" alt="">
          </div>
        </div>
        <hr>

        <sn-scroll-spy-section id="section1" for="foo">

             <div class="mt-2">
            <div class="bg-indigo-100 rounded-lg p-2 ">
                <a
              href="#"
              class="text-base font-semibold text-indigo-800 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"
              >Steg 1</a
            >
            </div>
            <p class="mt-2 text-gray-600 dark:text-gray-300">
              Välj vilken tjänst som du vill lägga till i din budget
            </p>


  <app-dropdown-select [data]="selectData"></app-dropdown-select>

  
            <hr />
          </div>
        </sn-scroll-spy-section>

       

          <sn-scroll-spy-section id="section2" for="foo">

<div class="mt-2">
            <div class="bg-indigo-100 rounded-lg p-2 ">
                <a
              href="#"
              class="text-base font-semibold text-indigo-800 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"
              >Steg 2</a
            >
            </div>
          <p class="mt-2 text-gray-600 dark:text-gray-300">
            Hur många mätningar beräknar du utföra?
          </p>
          <input
            [(ngModel)]="measurements"
            class="text-lg p-3 rounded border my-2"
            type="number"
          />
          <button
            (click)="createMeasurements()"
            class="text-lg rounded p-3 mx-1 bg-indigo-600 hover:bg-indigo-800 text-white"
          >
            Nästa
          </button>



          <hr />
        </div>

          </sn-scroll-spy-section>
        

        <ng-container *ngIf="budget">
          <div class="mt-2">
            <a
              href="#"
              class="text-xl font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"
              >Mätningar</a
            >
          </div>

          <div
            *ngFor="let mp of budget.measurements"
            class="mt-2 bg-gray-50 p-2 rounded-lg mb-2 shadow"
          >
            <p class="mt-2 text-gray-600 dark:text-gray-300">
              Ge din mätning ett beskrivande namn (ex. Hus A plan 10-15)
            </p>
            <input
              [(ngModel)]="mp.name"
              class="text-lg p-3 rounded border my-2 w-full"
              type="text"
            />
            <p class="mt-2 text-gray-600 dark:text-gray-300">
              Hur många mätpunkter beräknar du att denna mätning innehåller?
            </p>
            <input
              [(ngModel)]="mp.mps"
              class="text-lg block p-3 rounded border my-2"
              type="number"
            />
            <div class="flex">
              <div class="mx-1">
                <label for="">Borttagning av avjämningsmassa? </label>
              </div>
              <div>
                <input
                  [(ngModel)]="mp.levelingScreed"
                  class="self-center h-6 w-6"
                  type="checkbox"
                  name=""
                  id=""
                />
              </div>
            </div>
          </div>
        </ng-container>

        <div class="flex items-center justify-between mt-4">
          <button
            (click)="buildBlocks()"
            class="bg-gray-700 text-white p-2 rounded"
          >
            Build
          </button>
        </div>
      </div>
      <app-budget-table [budget]="budget"></app-budget-table>
    </div>
  </div>
  <div>
    <div
      class="flex flex-col w-64 px-4 py-8 bg-gray-50 border-r rounded-lg shadow-lg mr-2 ml-2 dark:bg-gray-800 dark:border-gray-600 sticky top-20"
    >
      <div class="flex flex-col justify-between flex-1 mt-6">
        <nav role="navigation" snScrollSpy id="foo">
          <a
            class="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md dark:bg-gray-700 dark:text-gray-200"
            snScrollSpyItem for="foo" href="#section1"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 11H5M19 11C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 11.8954 3.89543 11 5 11M19 11V9C19 7.89543 18.1046 7 17 7M5 11V9C5 7.89543 5.89543 7 7 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="mx-4 font-medium">Step 1</span>
          </a>

          <a
            class="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
            snScrollSpyItem for="foo" href="#section2"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="mx-4 font-medium">Step 2</span>
          </a>

          <a
            class="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
            href="#"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5V7M15 11V13M15 17V19M5 5C3.89543 5 3 5.89543 3 7V10C4.10457 10 5 10.8954 5 12C5 13.1046 4.10457 14 3 14V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V14C19.8954 14 19 13.1046 19 12C19 10.8954 19.8954 10 21 10V7C21 5.89543 20.1046 5 19 5H5Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="mx-4 font-medium">Step 3</span>
          </a>

          <a
            class="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
            href="#"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="mx-4 font-medium">Settings</span>
          </a>

          <hr class="my-6 dark:border-gray-600" />

          <a
            class="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
            href="#"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5V7M15 11V13M15 17V19M5 5C3.89543 5 3 5.89543 3 7V10C4.10457 10 5 10.8954 5 12C5 13.1046 4.10457 14 3 14V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V14C19.8954 14 19 13.1046 19 12C19 10.8954 19.8954 10 21 10V7C21 5.89543 20.1046 5 19 5H5Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="mx-4 font-medium">Tickets</span>
          </a>

          <a
            class="flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
            href="#"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="mx-4 font-medium">Settings</span>
          </a>
        </nav>
      </div>
      <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800">
        <div class="flex items-center">
          <span class="rounded-xl relative p-4 bg-purple-200">
            <svg
              width="40"
              fill="currentColor"
              height="40"
              class="text-purple-500 h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z"
              ></path>
            </svg>
          </span>
          <p class="text-md text-black dark:text-white ml-2">
            Totalt budgeterat
          </p>
        </div>
        <div class="flex flex-col justify-start">
          <p
            class="text-gray-700 dark:text-gray-100 text-4xl text-left font-bold my-4"
          >
            {{ totalPrice() | curency }}
            <span class="text-sm">
              SEK
            </span>
          </p>

        </div>
      </div>
    </div>
  </div>
</div>

</div>

