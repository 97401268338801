import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GanttService } from 'src/app/core/services/gantt.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-gantt-row',
  templateUrl: './gantt-row.component.html',
  styleUrls: ['./gantt-row.component.scss']
})
export class GanttRowComponent implements OnInit {

  numbers: number[]
  @Input() daysToRender: number
  @Input() rowId: any

  blocks$: BehaviorSubject<any> = new BehaviorSubject(null)
  row$: Observable<any>

  constructor(private gs:GanttService, private stateService: StateService) { }

  ngOnInit(): void {

    this.row$ = this.stateService.getRow(this.rowId)
    
    this.row$.subscribe((row:any) => {
      this.blocks$.next(row.blocks)
    })

    console.log(this.numbers)
    console.log(this.rowId)

    this.renderDays()

  }

  renderDays() {
    console.log('rendering days')
    this.numbers = undefined
    this.numbers = Array(this.daysToRender).fill(0).map((x,i)=>i);
    
  }

  updateBlock(event:any) {
    console.log('updating')
    console.log(event)

    //this.stateService.updateRow(this.rowId, {...event})

    this.stateService.updateBlock(this.rowId, event.blockIndex, event)

    

    

    //console.log(this.blocks[event.blockIndex])


    //this.blocks[event.blockIndex] = {...event}

    //this.gs.blocks$.next(this.blocks)

    /* this.blocks[event.blockIndex] = {
      startIndex:event.startIndex,
      drawLength: event.drawLength,
      title: event.title + ' (Updated)'
    } */


  }

}
