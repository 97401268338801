import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { WrapperComponent } from "./projects/components/wrapper/wrapper.component";
import { NavbarComponent } from "./projects/components/navbar/navbar.component";
import { SidebarComponent } from "./projects/components/sidebar/sidebar.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";
import { LoginComponent } from "./core/components/login/login.component";
import { ActivityComponent } from "./projects/components/activity/activity.component";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";

import { userReducer } from "./ngrx/reducers/user.reducer";
import { activitiesReducer } from "./ngrx/reducers/activities.reducer";
import { sidebarReducer } from "./ngrx/reducers/sidebar.reducer";
import { StateService } from "./core/services/state.service";
import { FirebaseService } from "./core/services/firebase.service";
import { UserEffects } from "./ngrx/effects/user.effects";
import { MyProjectsEffects } from "./ngrx/effects/myProjects.effects";
import { ActivitiesEffects } from "./ngrx/effects/activities.effects";
import { MyProfileComponent } from "./user/my-profile/my-profile.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreateActivityComponent } from "./projects/components/create-activity/create-activity.component";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { ActivitiesComponent } from "./projects/components/activities/activities.component";
import { MainComponent } from "./projects/components/main/main.component";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { ParticipantsComponent } from "./projects/components/participants/participants.component";
import { ParticipantComponent } from "./projects/components/participant/participant.component";
import { CompanyComponent } from "./projects/components/company/company.component";
import { BlockComponent } from "./projects/components/block/block.component";
import { CheckBlockComponent } from "./projects/components/check-block/check-block.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";

import { UserPickerComponent } from "./projects/components/user-picker/user-picker.component";
import { UserBlockComponent } from "./projects/components/user-block/user-block.component";
import { NgPipesModule } from "ngx-pipes";
import { HttpIntercept } from "./core/interceptors/http.interceptor";

import { NgxPaginationModule } from "ngx-pagination";
import { DocumentEditorComponent } from "./projects/components/document-editor/document-editor.component";
import { StatusComponent } from "./projects/components/status/status.component";
import { StatusPopoverComponent } from "./projects/components/status-popover/status-popover.component";
import { ProjectPlanningComponent } from "./projects/components/project-planning/project-planning.component";
import { DocumentDownloadComponent } from "./projects/components/document-download/document-download.component";

import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { WelcomeComponent } from "./projects/components/welcome/welcome.component";
import { myProjectsReducer } from "./ngrx/reducers/myProjects.reducer";
import { AllProjectsComponent } from "./projects/components/all-projects/all-projects.component";
import { BudgetingComponent } from "./projects/components/budgeting/budgeting.component";
import { BudgetTableComponent } from "./projects/components/budget-table/budget-table.component";
import { CurencyPipe } from "./pipes/curency.pipe";
import { DropdownSelectComponent } from "./components/dropdown-select/dropdown-select.component";
import { SimpleScrollSpyModule } from "angular-simple-scroll-spy";

import { InViewportModule } from "@thisissoon/angular-inviewport";
import { ScrollSpyModule } from "@thisissoon/angular-scrollspy";
import { RiskCardComponent } from "./projects/components/risk-card/risk-card.component";
import { RiskCardSingleComponent } from "./projects/components/risk-card-single/risk-card-single.component";
import { AddRiskComponent } from "./projects/components/add-risk/add-risk.component";
import { CommonRisksComponent } from "./projects/components/common-risks/common-risks.component";
import { CommonRiskContentComponent } from "./projects/components/common-risk-content/common-risk-content.component";
import { usersReducer } from "./ngrx/reducers/users.reducer";
import { UsersEffects } from "./ngrx/effects/users.effects";
import { ClosedComponent } from './core/components/closed/closed.component';
import { GanttChartComponent } from './components/gantt-chart/gantt-chart.component';
import { GanttChartWeekHeaderComponent } from './components/gantt-chart-week-header/gantt-chart-week-header.component';
import { GanttChartMonthHeaderComponent } from './components/gantt-chart-month-header/gantt-chart-month-header.component';
import { GanttChartMonthContentComponent } from './components/gantt-chart-month-content/gantt-chart-month-content.component';
import { GanttChartMonthContentRowComponent } from './components/gantt-chart-month-content-row/gantt-chart-month-content-row.component';
import { GanttService } from "./core/services/gantt.service";
import { GanttRulerDaysComponent } from './components/gantt-ruler-days/gantt-ruler-days.component';
import { GanttRulerDayComponent } from './components/gantt-ruler-day/gantt-ruler-day.component';
import { GanttRulerDaysMonthComponent } from './components/gantt-ruler-days-month/gantt-ruler-days-month.component';
import { GanttRulerWeeksComponent } from './components/gantt-ruler-weeks/gantt-ruler-weeks.component';
import { GanttRulerMonthsComponent } from './components/gantt-ruler-months/gantt-ruler-months.component';
import { GanttRowComponent } from './components/gantt-row/gantt-row.component';
import { GanttRowDayComponent } from './components/gantt-row-day/gantt-row-day.component';
import { rowsReducer } from "./ngrx/reducers/rows.reducer";
import { GanttRowTitleComponent } from './components/gantt-row-title/gantt-row-title.component';

const baseURL = 'https://systepv2.ew.r.appspot.com'

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

// Look at demo project to see how.
// Function for Creating the MSAL Instance

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      //   clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use.
      clientId: "7e96e826-0e78-4d8a-9ffe-7af7f11397f5", // PPE testing environment
      //   authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority:
        "https://login.microsoftonline.com/a21320bb-facd-466b-81b1-df45c6bc6325/", // PPE testing environment.
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}


// Configure the MSAL Interceptor
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(baseURL, [
    "api://32f92a40-ea15-4bc9-9a51-a2c471579f08/access_as_user",
  ]); // Prod environment. Uncomment to use.
  //protectedResourceMap.set('https://graph.microsoft-ppe.com/v2.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["api://32f92a40-ea15-4bc9-9a51-a2c471579f08/access_as_user"],
    },
    loginFailedRoute: "/login",
  };
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true },
];

@NgModule({
  declarations: [
    AppComponent,
    WrapperComponent,
    NavbarComponent,
    SidebarComponent,
    LoginComponent,
    ActivityComponent,
    MyProfileComponent,
    CreateActivityComponent,
    ActivitiesComponent,
    MainComponent,
    ParticipantsComponent,
    ParticipantComponent,
    CompanyComponent,
    BlockComponent,
    CheckBlockComponent,
    UserPickerComponent,
    UserBlockComponent,
    DocumentEditorComponent,
    StatusComponent,
    StatusPopoverComponent,
    ProjectPlanningComponent,
    DocumentDownloadComponent,
    WelcomeComponent,
    AllProjectsComponent,
    BudgetingComponent,
    BudgetTableComponent,
    CurencyPipe,
    DropdownSelectComponent,
    RiskCardComponent,
    RiskCardSingleComponent,
    AddRiskComponent,
    CommonRisksComponent,
    CommonRiskContentComponent,
    ClosedComponent,
    GanttChartComponent,
    GanttChartWeekHeaderComponent,
    GanttChartMonthHeaderComponent,
    GanttChartMonthContentComponent,
    GanttChartMonthContentRowComponent,
    GanttRulerDaysComponent,
    GanttRulerDayComponent,
    GanttRulerDaysMonthComponent,
    GanttRulerWeeksComponent,
    GanttRulerMonthsComponent,
    GanttRowComponent,
    GanttRowDayComponent,
    GanttRowTitleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    StoreModule.forRoot(
      {
        user: userReducer,
        activities: activitiesReducer,
        sideBar: sidebarReducer,
        myProjects: myProjectsReducer,
        users: usersReducer,
        rows: rowsReducer
      },
      {}
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([UserEffects, ActivitiesEffects, MyProjectsEffects, UsersEffects]),
    NgPipesModule,
    NgxPaginationModule,
    AngularFireFunctionsModule,
    InViewportModule,
    ScrollSpyModule.forRoot(),
    NgxSkeletonLoaderModule
  ],
  providers: [
    StateService,
    FirebaseService,
    GanttService,
    httpInterceptorProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
