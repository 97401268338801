<div class="w-64 shadow border-r bg-gray-100 top-16 h-full fixed">
    <div *ngIf="project$ | async as project">
        <div class="w-full flex p-4">
            <div class="w-10 h-10">
                <img src="../../../../assets/img/icons/skyscrapper.png" alt="">
            </div>
            <div class="w-auto ml-2 self-center">
                <p class="text-base font-bold">{{project.name}}</p>
                <p class="text-xs font-semibold text-blue-900">{{project.customer}}</p>
            </div>
        </div>
        <div class="w-full flex flex-col mt-2 p-4">
            <div class="w-full flex my-1 hover:bg-gray-300 bg-blue-800 text-white rounded p-2 cursor-pointer">
                <div class="w-6 h-6">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                      </svg>
                </div>
                <div class="text-sm self-center pl-2">
                    Översikt
                </div>
            </div>
                        <div (click)="deleteProject = true" class="w-full flex my-1 hover:bg-gray-300 bg-red-700 text-white rounded p-2 cursor-pointer">
                <div class="w-6 h-6">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                </div>
                <div class="text-sm self-center pl-2">
                    Radera projekt
                </div>
            </div>
            <div *ngIf="deleteProject" class="w-full bg-gray-700 mt-1 p-2 rounded">
                <p class="text-gray-100 text-sm">Är du säker på att du vill radera projektet: {{project.name}}</p>
                <div class="w-full flex my-2">
                    <button (click)="softDelete(project.id)" class="bg-green-500 hover:bg-green-200 cursor-pointer hover:text-gray-800 p-2 w-1/3 mx-auto text-center text-xs text-white rounded-lg">Ja</button>
                    <button (click)="deleteProject = false" class="bg-red-500 hover:bg-red-200 cursor hover:text-gray-800 p-2 w-1/3 mx-auto text-center text-xs text-white rounded-lg">Nej</button>
                </div>

            </div>
<!--             <div class="w-full flex my-1 hover:bg-gray-300 rounded p-2 cursor-pointer">
                <div class="w-6 h-6 text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                </div>
                <div class="text-sm text-gray-700 self-center pl-2">
                    Projektorganisation
                </div>
            </div> -->
<!--             <div class="w-full flex my-1 hover:bg-gray-300 rounded p-2 cursor-pointer">
                <div class="w-6 h-6 text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                      </svg>
                </div>
                <div class="text-sm text-gray-700 self-center pl-2">
                    Uppdragsplan
                </div>
            </div> -->
<!--             <div class="w-full flex my-1 hover:bg-gray-300 rounded p-2 cursor-pointer">
                <div class="w-6 h-6 text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                </div>
                <div class="text-sm text-gray-700 self-center pl-2">
                    Dokumentation
                </div>
            </div> -->
<!--             <div class="w-full flex flex-col pl-5">
                <div class="w-full flex my-1 hover:bg-gray-300 rounded p-1 cursor-pointer">
                    <div class="w-5 h-5 text-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                          </svg>
                    </div>
                    <div class="text-xs text-gray-700 self-center pl-2">
                        Riskanalys
                    </div>
                </div>
                <div class="w-full flex my-1  text-gray-700 mr-2 hover:bg-gray-300 rounded p-1 cursor-pointer">
                    <div class="w-5 h-5">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                          </svg>
                    </div>
                    <div class="text-xs self-center pl-2">
                        Mötesprotokoll
                    </div>
                </div>
                <div class="w-full flex my-1  text-gray-700 mr-2 hover:bg-gray-300 rounded p-1 cursor-pointer">
                    <div class="w-5 h-5">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z" clip-rule="evenodd" />
                          </svg>
                    </div>
                    <div class="text-xs text-blue-800 self-center pl-2">
                        Nytt dokument
                    </div>
                </div>
            </div> -->
        </div>
<!--         <div class="w-full mt-10 bg-gray-700">
            <p>Aktivitetslogg</p>
        </div> -->
    </div>
</div>
