import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


interface SelectData {
  value: string | number
  desc: string | number
}

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent implements OnInit {


  @Input() data:SelectData[]
  @Output() output: EventEmitter<SelectData> = new EventEmitter()

  open: boolean;
  selected: SelectData

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.open = !this.open
  }

  emitOutput = (data:SelectData) => {
    this.selected = data
    this.output.emit(data)
    this.toggle()
  }

}
