import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  project$:Observable<any>
  deleteProject: Boolean;

  constructor(private stateService:StateService, private firebaseService: FirebaseService, private router: Router) { }

  // TODO: Implement function to handle project settings in sidebar

  ngOnInit(): void {
    this.stateService.returnSidebarState()
    .subscribe((state) => {
      console.log(state)
      if (state.projectID) {
        this.project$ = this.stateService.getBooking(state.projectID)
      }
      
    })
  }

  softDelete(id:string) {
    this.firebaseService.softDeleteProject(id)
    .then(() => {
      this.router.navigateByUrl('')
    })
  }

}
