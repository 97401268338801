import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  BrowserUtils,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { GraphService } from "./core/services/graph.service";
import { AngularFireAnalytics } from "@angular/fire/analytics";

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Systep";
  isIframe = false;
  loginDisplay = false;
  profile!: ProfileType;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private location: Location,
    private http: HttpClient,
    private graph: GraphService,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    this.analytics
      .logEvent("my_test_event")
      .then((value) => {
        console.log("logged with analytics");
        console.log(value);
      })
      .catch((err) => {
        console.error("Something wen wrong");
        console.error(err);
      });

    const currentPath = this.location.path();
    // Dont perform nav if in iframe or popup, other than for front-channel logout
    this.isIframe =
      BrowserUtils.isInIframe() &&
      !window.opener &&
      currentPath.indexOf("logout") < 0; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
