import { Component, Input, OnInit } from '@angular/core';
import { GanttService } from 'src/app/core/services/gantt.service';

@Component({
  selector: 'app-gantt-ruler-days-month',
  templateUrl: './gantt-ruler-days-month.component.html',
  styleUrls: ['./gantt-ruler-days-month.component.scss']
})
export class GanttRulerDaysMonthComponent implements OnInit {

  @Input() days:number[]
 
  width:string

  constructor(private gs: GanttService) { }

  ngOnInit(): void {

    this.gs.daySize.subscribe((size:number) => {
      this.width = size.toString() + 'px'
    })

    console.log(this.width)
  }

}
