<div class="rounded-xl shadow p-2 absolute w-96 bg-white z-30" *ngIf="!users">Fetching users...</div>
<div class="rounded-xl shadow p-2 absolute w-96 bg-white z-30" *ngIf="users">
  <form [formGroup]="filterForm">
    <input
      class="w-full text-lg rounded p-1 pl-4 border mb-2"
      placeholder="Sök användare"
      type="text"
      formControlName="filterText"
    />
  </form>

  <app-user-block
    *ngFor="
      let user of users
        | filterBy: ['displayName']:filter
        | paginate: { itemsPerPage: 20, currentPage: p }
    "
    [user]="user"
    [size]="'full'"
    (selectedUser)="selected($event)"
  ></app-user-block>
</div>
