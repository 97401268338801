<div *ngIf="budget" class="w-full bg-gray-100 p-2 rounded-lg">

  <ng-container *ngIf="budget.measurements">
    <ng-container *ngIf="budget.measurements[0].rows">
        <div class="w-full p-2">
            <div class="bg-indigo-100 rounded-lg p-2 ">
                <a
              href="#"
              class="text-base font-semibold text-indigo-800 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"
              >Steg 3</a
            >
            </div>
          </div>
      <div
        *ngFor="let measurement of budget.measurements"
        class="w-full max-w-7xl mx-auto p-2 rounded-lg border  m-4 mb-8 shadow-lg"
      >
        <div class="w-full p-2">
          <p class="text-xl font-bold">{{ measurement.name }}</p>
        </div>
        <table class="table p-4 bg-white shadow rounded-lg w-full">
          <thead>
            <tr>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900 text-left"
              >
                Desc
              </th>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900"
              >
                Artikelkod
              </th>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900"
              >
                Antal
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-gray-700"
              *ngFor="let row of measurement.rows; let i = index"
            >
              <td class="border-b-2 p-4 dark:border-dark-5">
                {{ row.desc }}
              </td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center uppercase"
              >
                <span class="bg-blue-100 text-xs text-blue-800 rounded p-1">{{
                  row.art
                }}</span>
              </td>
              <td class="border-b-2 p-4 dark:border-dark-5 text-center">
                {{ row.count }}{{ row.unit }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="p-2">
          <p class="text-lg font-bold">Artikelsumering</p>
        </div>
        <table class="table p-4 bg-white shadow rounded-lg w-full">
          <thead>
            <tr>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900 text-left"
              >
                Desc
              </th>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900"
              >
                Artikelkod
              </th>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900"
              >
                á pris
              </th>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900"
              >
                Antal
              </th>
              <th
                class="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900"
              >
                Pris
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-gray-700" *ngFor="let key of measurement.sum.keys">
              <td class="border-b-2 p-4 dark:border-dark-5">
                {{ showArt(key).desc }}
              </td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center uppercase"
              >
                <span class="bg-blue-100 text-xs text-blue-800 rounded p-1">{{
                  key
                }}</span>
              </td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center uppercase"
              >
                {{ showArt(key).price }}kr
              </td>
              <td class="border-b-2 p-4 dark:border-dark-5 text-center">
                {{ measurement.sum[key] }}
              </td>
              <td class="border-b-2 p-4 dark:border-dark-5 text-center">
                {{ showArt(key).price * measurement.sum[key] | curency }}kr
              </td>
            </tr>
            <tr class="text-gray-700">
              <td class="border-b-2 p-4 dark:border-dark-5"></td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center uppercase"
              ></td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center uppercase"
              ></td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center font-bold"
              >
                Total
              </td>
              <td
                class="border-b-2 p-4 dark:border-dark-5 text-center text-green-800 uppercase"
              >
                {{ sumPriceMeasurement(measurement) | curency }}kr
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <hr />
    </ng-container>
  </ng-container>
</div>
