<div class="max-w-2xl mx-auto">
  <div class="w-full">
    <div class="w-full max-w-7xl mx-auto flex flex-col rounded-lg">

        <div class="w-full">
        <img src="../../../assets/img/Space Mission Control Center.svg" alt="">
        </div>
       <div class="w-full text-center">
        <h1 class="text-5xl heading">Välkommen till Systep <span><img class="inline-block w-12" src="../../../../assets/img/icons/icons/favicon.png" alt=""></span></h1>
        <p class="text-lg">Vänligen komplittera din information nedan</p>

</div> 
    </div>
</div>
</div>
<div class="max-w-2xl mx-auto" *ngIf="user$ | async as user">
  <div>
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="w-full flex flex-col mt-5">
        <div class="w-full my-2">
          <label class="block text-xs font-semibold ml-6 text-gray-700">
            Namn
          </label>
          <input
          readonly
            class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
            type="text"
            formControlName="displayName"
          />
        </div>
        <div class="w-full my-2">
          <label class="block text-xs font-semibold ml-6 text-gray-700">
            Epost
          </label>
          <input
          readonly
            class="block text-3xl rounded-full bg-gray-100 text-gray-700 focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
            type="text"
            formControlName="email"
          />
        </div>
        <div class="w-full my-2">
          <label class="block text-xs font-semibold ml-6 text-gray-700">
            Telefon
          </label>
          <input
            class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
            type="text"
            placeholder="Vänligen ange ditt telefonnummer"
            formControlName="phone"
          />
        </div>
        <div class="w-full my-2">
          <label class="block text-xs font-semibold ml-6 text-gray-700">
            Företag
          </label>
          <select
            class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
            type="text"
            formControlName="company"
          >
          <option *ngFor="let company of companies" [value]="company.value">{{company.name}}</option>
        </select>
        </div>
        <div class="w-full my-2">
            <label class="block text-xs font-semibold ml-6 text-gray-700">
              Land
            </label>
            <select
              class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
              type="text"
              formControlName="country"
            >
            <option value="se">Sverige</option>
            <option value="fi">Finland</option>
          </select>
          </div>
          <div class="w-full my-2">
            <label class="block text-xs font-semibold ml-6 text-gray-700">
              Stad
            </label>
            <input
              class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
              type="text"
              placeholder="Vart befinner du dig?"
              formControlName="city"
            />
          </div>
          <div>
            <button class="text-3xl p-2 rounded-full text-white mt-4" [class.bg-gray-500]="!userForm.valid" [class.bg-blue-800]="userForm.valid" type="submit" [disabled]="!userForm.valid">Save profile information</button>
          </div>
      </div>
    </form>
  </div>
</div>
