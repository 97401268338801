import * as actions from "../actions/myProjects.actions";
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";

// Main Data Interface

// Entity adapter

export const myProjectsAdapter = createEntityAdapter<any>();
export interface State extends EntityState<any> {}

// Deafault data / initial state

const defaultProject = {
  ids: [],
  entities: {},
};

export const initialState: State = myProjectsAdapter.getInitialState();

export function myProjectsReducer(
  state: State = initialState,
  action: actions.ProjectActions
) {
  switch (action.type) {
    case actions.QUERY:
      return initialState;

    case actions.ADDED:
      return myProjectsAdapter.addOne(action.payload, state);

    case actions.MODIFIED:
      return myProjectsAdapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload,
        },
        state
      );

    case actions.REMOVED:
      return myProjectsAdapter.removeOne(action.payload.id, state);

    default:
      return state;
  }
}

export const getMyProjectsState = createFeatureSelector<State>("myProjects");

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = myProjectsAdapter.getSelectors(getMyProjectsState);

export const selectEntity = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
);

export const selectEntityBookingLocation = createSelector(
  selectEntities,
  (entities, props) => {
    entities.location === props.location;
  }
);
