<div class="w-full flex">
  <div class="w-96 bg-gray-100 rounded  overflow-test">
    <div class="spacer w-full rounded bg-gray-50 mt-0.5 flex flex-col">
      <div style="border-right: 1px solid #999" class="inline-block text-xs text-center uppercase bg-gray-700 text-white p-1 month rounded w-full">Months</div>
      <div
  class="text-center text-xs inline-block week bg-gray-300 p-1 w-full"
>
Weeks
</div>

<div
  class="text-center text-xs inline-block week month bg-gray-100 p-1 w-full"
>
Days
</div>

    </div>
    <ng-container *ngIf="rowIds$ | async as rowIds">
    <app-gantt-row-title *ngFor ="let rowId of rowIds" [rowId]="rowId"></app-gantt-row-title>
    </ng-container>
  </div>
  <div #gantt class="w-full h-full flex flex-col overflow-test">
    <app-gantt-ruler-months [monthsToRender]="months"></app-gantt-ruler-months>
    <app-gantt-ruler-weeks [firstMonth]="months[0]" [startWeek]="startWeek" [daysToRender]="totalDays"></app-gantt-ruler-weeks>
    <app-gantt-ruler-days *ngIf="daySize > 25" [monthsToRender]="monthsToRender"></app-gantt-ruler-days>
    <ng-container *ngIf="rowIds$ | async as rowIds">
      <app-gantt-row *ngFor="let rowId of rowIds" [rowId]="rowId" [daysToRender]="totalDays"></app-gantt-row>
    </ng-container>
    
  </div>
</div>

<button (click)="zoomIn()">zoom in</button>
<button (click)="zoomOut()">zoom out</button>
