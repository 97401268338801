import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  DoCheck,
  AfterViewChecked,
} from "@angular/core";
import { GanttService } from "src/app/core/services/gantt.service";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { StateService } from "src/app/core/services/state.service";
import { rowsAdapter } from "src/app/ngrx/reducers/rows.reducer";

class Block {
  constructor(block: any) {
    const b: any = {
      title: block.title,
      startDate: block.startDate,
      stopDate: block.stopDate,
      color: block.color,
    };

    return b;
  }
}

@Component({
  selector: "app-gantt-row-day",
  templateUrl: "./gantt-row-day.component.html",
  styleUrls: ["./gantt-row-day.component.scss"],
})
export class GanttRowDayComponent implements OnInit {
  @ViewChild("dragright") dragRight: ElementRef;
  @ViewChild("dragleft") dragLeft: ElementRef;
  @ViewChild("move") move: ElementRef;

  @Input() blocks$: BehaviorSubject<any>;
  @Input() index: number;
  @Input() rowId: string;

  @Output() update: EventEmitter<any> = new EventEmitter();

  block: any;

  width: string;
  draw: boolean;
  size: number;
  drawSize: string;
  margin: string;
  marginLeft: string;
  blockIndex: number;

  currentWidth: number;
  currentMargin: number;
  backGround: string;
  marginTop: string;

  direction: string;

  listnerAdded: boolean;

  mouseRightDown: boolean;
  mouseLeftDown: boolean;
  mouseMoveDown: boolean;
  initial: number = 0;
  diff: number;

  blocks: any

  // Block props
  drawLength: number;
  startIndex: number;
  startDate: string;
  stopDate: string;
  title: string;
  color: string;
  level: string;
  blockId: string;

  constructor(private gs: GanttService, private stateService: StateService) {}

  reset() {
    this.currentWidth = undefined;
    this.currentMargin = undefined;
    this.listnerAdded = false;
    this.margin = undefined;
  }

  ngOnInit(): void {
    //console.log(new Block({title: 'test', startDate: 'test2'}))

    this.gs.daySize.subscribe((size: number) => {
      this.size = size;
      this.width = size.toString() + "px";

      this.stateService.getRow(this.rowId).subscribe((row) => {
        const keys = Object.keys(row.blocks);
        const blocks = [];

        this.blocks = row.blocks

        for (let i = 0; i < keys.length; i++) {
          blocks.push({ ...row.blocks[keys[i]], id: keys[i] });
        }

        // console.log(blocks);
        this.checkIfDraw(blocks);
      });

      /* this.blocks$.subscribe((blocks) => {
       // console.log(blocks)
        if (blocks !== null)
        this.checkIfDraw(blocks)
       // console.log(blocks)
      }) */
    });
  }

  /* ngAfterViewInit() {

    if(this.draw) {

      this.addListners()
    
    
  }

  } */

  checkIfDraw(blocks: any) {
    this.reset();

    this.draw = false;

    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];

      const startIndex = this.gs.calculateIndex(block.startDate);

      // Check if current index should draw

      if (startIndex === this.index) {
        // Set Block Props
        this.title = block.title;
        this.startDate = block.startDate;
        this.stopDate = block.stopDate;
        this.color = block.color;
        this.level = block.level;
        this.blockId = block.id;

        this.marginTop = (15 * block.level).toString() + "px";

        this.backGround = this.setBg(block.color);

        this.block = block;

        console.log(`${this.index} should draw now`);

        // Calculate length to draw
        this.drawSize =
          (
            this.size * this.gs.calculateLength(block.startDate, block.stopDate)
          ).toString() + "px";
        this.drawLength = this.gs.calculateLength(
          block.startDate,
          block.stopDate
        );

        //console.log(block)
        this.draw = true;
        this.blockIndex = i;
      }
    }
  }

  /* background-color: rgba(0, 50, 189, 0.7); Blue */
  /* background-color: rgba(26, 136, 68, 0.7); Green*/
  /* background-color: rgba(114, 53, 143, 0.7); Purple*/
  /* background-color: rgba(180, 80, 167, 0.7); Pink*/
  /* background-color: rgba(153, 23, 23, 0.7); Red*/

  setBg(color: string) {
    if (color === "blue") return "rgba(0, 50, 189, 0.7)";
    else if (color === "green") return "rgba(26, 136, 68, 0.7)";
    else if (color === "purple") return "rgba(114, 53, 143, 0.7)";
    else if (color === "pink") return "rgba(180, 80, 167, 0.7)";
    else if (color === "red") return "rgba(153, 23, 23, 0.7)";
    else return "rgba(0, 50, 189, 0.7)";
  }

  attatchListner() {
    if (!this.listnerAdded) {
      this.listnerAdded = true;
      this.addListners();
    }
  }

  addListners() {
    console.log("listner added");

    // Eventlistner for Resize right

    this.dragRight.nativeElement.addEventListener("mousedown", () => {
      console.log("Mouse Down");
      this.mouseRightDown = true;
    });

    // Eventlistner for Resize Left

    this.dragLeft.nativeElement.addEventListener("mousedown", () => {
      console.log("Mouse Down");
      this.mouseLeftDown = true;
    });

    // Eventlistner for Move
    this.move.nativeElement.addEventListener("mousedown", () => {
      console.log("Mouse Down");
      this.mouseMoveDown = true;
    });

    // General event listner for mouse up

    document.addEventListener("mousemove", (event) => {
      // Handle mouse up on Resize right

      if (this.mouseRightDown) {
        if (this.initial === 0) {
          this.initial = event.pageX;
        } else {
          const diff = event.pageX - this.initial;
          this.diff = diff;
          this.reDraw(diff);
        }
      }

      // Handle mouse up on Resize Left

      if (this.mouseLeftDown) {
        if (this.initial === 0) {
          this.initial = event.pageX;
        } else {
          const diff = event.pageX - this.initial;
          const diff2 = this.initial - event.pageX;
          //console.log(diff)
          //console.log(diff2)

          this.margin = diff.toString() + "px";
          this.reDraw(diff2);

          if (diff < 0) {
            // add diff value as margin-left
            this.direction = "back";
          }
          if (diff > 0) {
            this.direction = "forward";
          }
          //this.reDraw(diff)
        }
      }

      if (this.mouseMoveDown) {
        if (this.initial === 0) {
          this.initial = event.pageX;
        } else {
          const diff = event.pageX - this.initial;
          this.diff = diff;
          const diff2 = this.initial - event.pageX;
          // console.log(diff)
          //console.log(diff2)

          this.margin = diff.toString() + "px";
          //this.reDraw(diff2)

          if (diff < 0) {
            // add diff value as margin-left
            this.direction = "back";
          }
          if (diff > 0) {
            this.direction = "forward";
          }
          //this.reDraw(diff)
        }
      }
    });

    document.addEventListener("mouseup", () => {
      console.log("Mouse Up");
      if (this.mouseLeftDown || this.mouseRightDown || this.mouseMoveDown) {
        this.mouseUp();
      }
    });
  }

  reDraw(value: number) {
    const currentWidth = this.size * this.drawLength;
    const newWidth = currentWidth + value;

    this.currentWidth = newWidth;

    this.drawSize = newWidth.toString() + "px";
  }

  mouseUp() {
    let newIndex: number;

    if (this.mouseRightDown) {
      this.mouseRightDown = false;
      this.initial = 0;
      //console.log(this.currentWidth)
      const daysToDraw = Math.round(this.currentWidth / this.size);
      const stopDate = moment(this.startDate)
        .add(daysToDraw - 1, "days")
        .format("YYYY-MM-DD");
      this.stopDate = stopDate;
      //console.log(daysToDraw)
      this.drawSize = (this.size * daysToDraw).toString() + "px";
      this.drawLength = daysToDraw;

      newIndex = this.index;
    }

    if (this.mouseLeftDown) {
      this.mouseLeftDown = false;
      this.initial = 0;
      const daysToDraw = Math.round(this.currentWidth / this.size);

      const startDate = moment(this.stopDate)
        .subtract(daysToDraw - 1, "days")
        .format("YYYY-MM-DD");
      this.startDate = startDate;

      /*          // Old number of days
         console.log('Old Num')
         console.log(this.block.drawLength)

        // Old number of days
        console.log('New Num')
        console.log(daysToDraw)


        console.log('Current index')
        console.log(this.index) */

      const dayDiff = daysToDraw - this.block.drawLength;
      /*         console.log('Day diff')
        console.log(dayDiff)

        console.log('Direction')
        console.log(this.direction) */

      if (dayDiff < 0 && this.direction === "forward") {
        newIndex = this.index - dayDiff;
      } else {
        newIndex = this.index - dayDiff;
      }

      //console.log("new index");
      //console.log(newIndex);

      /* if (this.direction === 'back') {
          console.log('new index')
          console.log(this.index - dayDiff)
          newIndex = this.index - dayDiff
        }

        if (this.direction === 'forward') {
          console.log('new index')
          console.log(this.index + dayDiff)
          newIndex = this.index - dayDiff
        } */

      this.drawSize = (this.size * daysToDraw).toString() + "px";
      this.drawLength = daysToDraw;
    }

    if (this.mouseMoveDown) {
      this.mouseMoveDown = false;
      this.initial = 0;
      //console.log(this.currentWidth)
      const daysToDraw = Math.round(this.currentWidth / this.size);

      const daysToMove = Math.round(this.diff / this.size);
      newIndex = daysToMove + this.index;

      console.log(daysToMove);

      if (daysToMove < 0) {
        const startDate = moment(this.startDate)
          .subtract(Math.abs(daysToMove), "days")
          .format("YYYY-MM-DD");
        const stopDate = moment(this.stopDate)
          .subtract(Math.abs(daysToMove), "days")
          .format("YYYY-MM-DD");
        this.startDate = startDate;
        this.stopDate = stopDate;
      }

      if (daysToMove > 0) {
        const startDate = moment(this.startDate)
          .add(Math.abs(daysToMove), "days")
          .format("YYYY-MM-DD");
        const stopDate = moment(this.stopDate)
          .add(Math.abs(daysToMove), "days")
          .format("YYYY-MM-DD");
        this.startDate = startDate;
        this.stopDate = stopDate;
      }
    }

    // Emit updated block
    this.emitNewBlock(newIndex);
  }

  emitNewBlock(startIndex: number) {
    const row = {
      blocks: {...this.blocks},
    };

    row.blocks[this.blockId] = {
      title: this.title,
      startDate: this.startDate,
      stopDate: this.stopDate,
      level: this.level,
      color: this.color,
      blockIndex: startIndex,
    };
    //this.update.emit(block)

    this.stateService.updateRow(this.rowId, row);

    // console.log(block)
  }

  checkDiff() {
    // console.log(this.diff)
    if (this.diff > this.size / 2) {
      this.diff = 0;
      this.block.drawLength++;
      this.drawSize = (this.size * this.block.drawLength).toString() + "px";
    }
  }
}
