import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { StateService } from 'src/app/core/services/state.service';
import { usersReducer } from 'src/app/ngrx/reducers/users.reducer';

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {

  @Input() participant:any
  @Input() size:any
  @Input() rm:boolean
  @Input() me:boolean
  user:any

  constructor(private firebaseService:FirebaseService, private graph: GraphService, private state:StateService) { }

  ngOnInit(): void {

    
    //console.log(this.rm)

    console.log(this.participant)

    // get the participant ID
    const getID = () => {
      if (this.participant.id) return this.participant.id
      else return this.participant
    }

    const getUser = () => {
      // Get the logged in user if this.me = true
      if (this.me) {
        return this.state.getUser(localStorage.graphID)
      } else return this.state.getUser(getID())
    }

    getUser()
    .subscribe((user) => {
      if (user) {
        this.user = {...this.participant, ...user}
      }
      
    })
  }

}
