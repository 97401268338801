import { Component, Input, OnInit } from '@angular/core';
import { GanttService } from 'src/app/core/services/gantt.service';

@Component({
  selector: 'app-gantt-ruler-months',
  templateUrl: './gantt-ruler-months.component.html',
  styleUrls: ['./gantt-ruler-months.component.scss']
})
export class GanttRulerMonthsComponent implements OnInit {

  @Input() monthsToRender: any
  
  size: number
  

  constructor(private gs: GanttService) { }

  ngOnInit(): void {

this.gs.daySize.subscribe((size:number) => {
  this.size = size
})

    //console.log(this.monthsToRender)
  }

  calculateWidth(days:number []) {
    return (days.length * this.size).toString() + 'px'
  }

}
