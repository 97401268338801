<!-- <div class="fixed top-1 w-full h-12 shadow bg-white flex z-50">
    <div class="flex w-full">
<button class="my-auto ml-3">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 my-auto self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
      </svg>
</button>
<div class="p-2 ml-2">
    <img class="h-9" src="../../../../assets/img/social.png" alt="">
</div>

<div class="p-3 h-full mx-1 active my-auto hover:bg-blue-100 sm:visible"><a class="font-semibold my-auto" href="">Startsida</a></div>
<div class="p-3 h-full mx-1 my-auto hover:bg-blue-100"><a class="font-semibold my-auto" href="">Aktiviteter</a></div>
<div class="p-3 h-full mx-1 my-auto"><a class="font-semibold my-auto rounded bg-blue-800 hover:bg-gray-800 text-xs p-1 text-white" href=""><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mb-0.5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
  </svg>Skapa ny aktivitet</a></div>
  <div class="w-auto">
    
  </div>
    </div>
    <div class="flex">
        <div class="p-3 h-full my-auto hover:bg-blue-100"><a class="font-semibold my-auto" href=""><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg></a></div>
          <div class="p-3 h-full my-auto hover:bg-blue-100"><a class="font-semibold my-auto" href=""><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg></a></div>
    </div>

</div> -->

<nav class="bg-gray-700 shadow-xl border-b border-gray-600 fixed top-0 z-10 w-full">
  <div class="w-full mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
        <!-- Mobile menu button-->
        <button (click)="menuOpen = !menuOpen" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.

            Heroicon name: outline/menu

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <!--
            Icon when menu is open.

            Heroicon name: outline/x

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img class="h-9" src="../../../../assets/img/icons/icons/favicon.png" alt="">
        </div>
                <div class="flex-shrink-0 flex items-center heading text-xl ml-2 text-white">
          Systep
        </div>
        <div class="hidden md:block sm:ml-6">
          <div class="flex space-x-4">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <a routerLink="dashboard" routerLinkActive="bg-blue-800" class="hover:bg-gray-900  text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">Startsida</a>

            <a routerLink="allprojects" routerLinkActive="bg-blue-800" class="text-gray-100 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Alla Projekt</a>

<!--             <a href="#" class="text-gray-100 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium">My activities</a>

            <a href="#" class="text-gray-100 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Calendar</a> -->
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button (click)="profileMenu = !profileMenu" type="button" class="flex text-sm rounded-full" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <app-participant [size]="'sm'" [me]="true"></app-participant>
            </button>
          </div>

          <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
          <div *ngIf="profileMenu" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
            <!-- Active: "bg-gray-100", Not Active: "" -->
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0">Your Profile</a>
            <a (click)="signOut()" (click)="profileMenu = false" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div *ngIf="menuOpen" class="md:hidden" id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <a (click)="menuOpen = false" routerLink="dashboard" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Startsida</a>

      <a (click)="menuOpen = false" routerLink="allprojects" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Alla projekt</a>

<!--       <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>

      <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a> -->
    </div>
  </div>
</nav>

