
import { Action } from '@ngrx/store';
import { Project } from '../reducers/dryfix.reducer'

export const QUERY =    '[My Activities] query activities'

export const ADDED =    '[My Activities] added'
export const MODIFIED = '[My Activities] modified'
export const REMOVED =  '[My Activities] removed'

export const UPDATE =   '[My Activities] update'
export const SUCCESS =   '[My Activities] update success'
export const CREATE =   '[My Activities] create'

export class Query implements Action {
    readonly type = QUERY;
    constructor() {}
}

export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Project) {}
}

export class Create implements Action {
    readonly type = CREATE;
    constructor(public payload: any) {}
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Project) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Project) {}
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: Partial<Project>) {}
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

export type ProjectActions = 
Query |
Added |
Modified |
Removed |
Create |
Success;