import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-block",
  templateUrl: "./block.component.html",
  styleUrls: ["./block.component.scss"],
})
export class BlockComponent implements OnInit {
  @Input() block: any;
  @Input() blockIndex: number;

  header: boolean;
  paragraph: boolean;
  customElement: boolean;
  customElementName: boolean;
  checklist: boolean;
  anyButton: boolean;
  checklistItems: any[]
  level: number;
  text: string;
  link: string;
  class: string;
  openLink:boolean

  constructor() {}

  ngOnInit(): void {
    if (this.block.type === "header") this.isHeader()
    if (this.block.type === "paragraph") this.isParagraph()
    if (this.block.type === "checklist") this.isChecklist()
    if (this.block.type === "AnyButton") this.isAnyButton()
  }

  // Check if the provided block is of type header
  isHeader() {
    this.header = true;
    this.level = this.block.data.level;
    this.text = this.block.data.text;
    if (this.block.data.level === 1) this.class = 'font-bold text-2xl mb-2 uppercase w-full p-2 text-gray-900 bg-blue-100 rounded-lg'
    if (this.block.data.level === 2) this.class = 'ml-2 text-gray-800 text-xl font-semibold'
    if (this.block.data.level === 3) this.class = 'ml-2 text-gray-800 text-lg font-semibold leading-tight'
    if (this.block.data.level === 4) this.class = 'ml-2 text-gray-800 text-base font-bold'
  }

  // Check if the provided block is of type Paragraph
  isParagraph() {
    // look for custom tags in the paragraph for generating custom blocks
    if (this.block.data.text === '#RISK#') {
      this.customElement = true;
      this.customElementName = this.block.data.text
    } else {
      this.paragraph = true;
      this.text = this.block.data.text
    }
  }

  // Check if the provided block is of type Paragraph
  isChecklist() {
    this.checklist = true;
    this.checklistItems = this.block.data.items;
  }
  // Check if the provided block is of type Button
  isAnyButton() {
    this.anyButton = true;
    this.text = this.block.data.text;
    this.link = this.block.data.link;
  }
}
