<div
  class="w-full flex flex-col h-screen z-50 top-0 left-0 fixed backdrop backdrop-filter backdrop-blur"
>
  <div
    class="w-full max-w-7xl flex xl:mx-auto overflow-scroll rounded-lg mx-5  bg-white mt-10 shadow-xl"
  >
    <div
      class="w-72 border-r sticky top-1 bg-gray-50 border-gray-300 hidden lg:block"
    >
      <div class="dark:bg-gray-800 sticky top-1 rounded-lg">
        <div class="flex flex-col md:flex-row md:justify-around">
          <div class="w-72">
            <nav class="mt-10 px-6 ">
              <a
                class="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                (click)="setPage('start')"
                [class.active]="isPage('start')"
              >
                <span class="mx-4 text-lg font-normal">
                  Start
                </span>
                <span class="flex-grow text-right"> </span>
              </a>
              <a
                class="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-800 dark:text-gray-100 rounded-lg dark:bg-gray-600"
                (click)="setPage('rbk')"
                [class.active]="isPage('rbk')"
              >
                <span class="mx-4 text-lg font-normal">
                  Fuktmätning i betong
                </span>
                <span class="flex-grow text-right"> </span>
              </a>
              <a
                class="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                (click)="setPage('ttl')"
                [class.active]="isPage('ttl')"
              >
                <span class="mx-4 text-lg font-normal">
                  Lufttäthetsuppdrag
                </span>
                <span class="flex-grow text-right"> </span>
              </a>
              <a
                class="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                (click)="setPage('utr')"
                [class.active]="isPage('utr')"
              >
                <span class="mx-4 text-lg font-normal">
                  Utredningsuppdrag
                </span>
                <span class="flex-grow text-right"> </span>
              </a>
              <a
                class="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                (click)="setPage('kon')"
                [class.active]="isPage('kon')"
              >
                <span class="mx-4 text-lg font-normal">
                  Konsultuppdrag (allmänt)
                </span>
                <span class="flex-grow text-right"> </span>
              </a>
              <a
                class="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                (click)="setPage('avf')"
                [class.active]="isPage('avf')"
              >
                <span class="mx-4 text-lg font-normal">
                  Avfuktningsuppdrag
                </span>
                <span class="flex-grow text-right"> </span>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-full p-4 pb-8">
      <div class="fixed w-full top-10 left-0 p-1">
        <div class="flex w-full max-w-7xl mx-auto">
          <div class="w-72 hidden lg:block"></div>
          <div class="w-full pl-20 pr-10">
                  <header
            class="w-full mx-auto  shadow-lg semi-transparent dark:bg-gray-700 items-center h-16 rounded-2xl z-40"
          >
            <div
              class="relative z-20 flex flex-col justify-center h-full px-3 mx-auto flex-center"
            >
              <div
                class="relative items-center pl-1 flex w-full lg:max-w-68 sm:pr-2 sm:ml-0"
              >
                <div
                  class="container relative left-0 z-50 flex w-3/4 h-auto h-full"
                >
                  <div
                    class="relative flex items-center w-full h-full group"
                  >
                <p class="text-sm font-semibold uppercase mt-1">Arbetsmiljörisker {{setTitle()}}</p>


                  </div>
                </div>
                <div
                  class="relative p-1 flex items-center justify-end w-1/4 ml-5 mr-4 sm:mr-0 sm:right-auto"
                >
                  <a (click)="close()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </header> 
          </div>
     
        </div>
      </div>

      <div class="w-full mt-20">
        <div class="container mx-auto px-6 p-6 bg-white dark:bg-gray-800">
          <div class="mb-16 text-center">
            <h2
              class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
            >
              Risker i uppdrag
            </h2>
            <p
              class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl"
            >
              Vanligt förekommande arbets- och miljörisker vid genomförande av:
            </p>
          </div>
          <div
            class="grid grid-cols-1 lg:grid-cols-3 gap-2 my-12 dark:text-white"
          >
            <div
              class="w-full p-8 rounded-lg bg-gray-50 border hover:bg-indigo-100"
              [class.bg-indigo-200]="isPage('rbk')"
              (click)="setPage('rbk')"
            >
              <div class="flex items-center mb-2">
                <div
                  class="text-base text-indigo-600 font-semibold tracking-wide uppercase self-center"
                >
                  Fuktmätningar i betong
                </div>
              </div>

            </div>
            <div
              class="w-full p-8 rounded-lg bg-gray-50 border hover:bg-indigo-100"
              [class.bg-indigo-200]="isPage('ttl')"
              (click)="setPage('ttl')"
            >
              <div class="flex items-center mb-2">
                <div
                  class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
                >
                  Lufttäthetsuppdrag
                </div>
              </div>

            </div>
            <div
              class="w-full p-8 rounded-lg bg-gray-50 border hover:bg-indigo-100"
              [class.bg-indigo-200]="isPage('utr')"
              (click)="setPage('utr')"
            >
              <div class="flex items-center mb-2">
                <div
                  class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
                >
                  Utredningsuppdrag
                </div>
              </div>

            </div>
            <div
              class="w-full p-8 rounded-lg bg-gray-50 border hover:bg-indigo-100"
              [class.bg-indigo-200]="isPage('kon')"
              (click)="setPage('kon')"
            >
              <div class="flex items-center mb-2">
                <div
                  class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
                >
                  Konsultuppdrag (allmänt)
                </div>
              </div>

            </div>
            <div
              class="w-full p-8 rounded-lg bg-gray-50 border hover:bg-indigo-100"
              [class.bg-indigo-200]="isPage('avf')"
              (click)="setPage('avf')"
            >
              <div class="flex items-center mb-2">
                <div
                  class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
                >
                  Avfuktningsuppdrag
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

<app-common-risk-content *ngIf="isPage('rbk')" [commonRisk]="commonRisks.rbk"></app-common-risk-content>
<app-common-risk-content *ngIf="isPage('ttl')" [commonRisk]="commonRisks.ttl"></app-common-risk-content>
<app-common-risk-content *ngIf="isPage('utr')" [commonRisk]="commonRisks.utr"></app-common-risk-content>
<app-common-risk-content *ngIf="isPage('kon')" [commonRisk]="commonRisks.kon"></app-common-risk-content>
<app-common-risk-content *ngIf="isPage('avf')" [commonRisk]="commonRisks.avf"></app-common-risk-content>
    </div>
  </div>
</div>
