import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";

import firebase from "firebase/app";
import "firebase/auth";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, retry, retryWhen, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  provider = new firebase.auth.OAuthProvider("microsoft.com");
  baseURL: string = "https://systepv2.ew.r.appspot.com";
  //baseURL: string = "http://localhost:8080";

  waitingForAuth: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private auth: AngularFireAuth) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.accessToken}`,
    }),
  };

  //TODO: Update to use new custom auth server

  private handleError(error: HttpErrorResponse, request) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError("Something bad happened; please try again later.");
  }

  test() {
    return this.http.get("https://google.se");
  }

  getApiConsent() {
    this.http.get(`https://login.microsoftonline.com/a21320bb-facd-466b-81b1-df45c6bc6325/oauth2/v2.0/authorize?
    client_id=32f92a40-ea15-4bc9-9a51-a2c471579f08
    &redirect_uri=http://localhost:5000/spa
    &response_mode=query
    &response_type=code
    &scope=
    https%3A%2F%2Fgraph.microsoft.com%2FUser.ReadBasic.All%20
    &state=12345`).subscribe((res) => {
      console.log(res)
    })
  }

  me() {
    return this.http.get(
      `${this.baseURL}/graph/me`
      //this.httpOptions
    );
  }

  getProfile() {
    this.http.get(`${this.baseURL}/graph/me`).subscribe((profile) => {
      console.log(profile);
    });
  }

  getFirebaseToken = () =>
    new Promise((resolve, reject) => {
      const sub = this.http
        .get(`${this.baseURL}/firebase/token`)
        .subscribe((token) => {
          resolve(token);
          //sub.unsubscribe();
        });
    });

  getUsers() {
    this.http.get(`${this.baseURL}/graph/users`).subscribe((profile) => {
      console.log(profile);
    });
  }

  users() {
    return this.http.get(`${this.baseURL}/graph/users`);
  }

  user(id: string) {
    return this.http.get(`${this.baseURL}/graph/user/${id}`);
  }

  me$() {
    return this.http.get(
      `${this.baseURL}/graph/me`
      // this.httpOptions
    );
  }

  getImage(imageUrl: string): Observable<any> {
    return this.http.get(imageUrl, { responseType: "blob" });
  }

  //TODO: Create NGRX store to chache list of users
  getAllUsers = () =>
    new Promise<any>((resolve, reject) => {
      console.log("called");

      let users = [];

      const getUsers = (url: string) => {
        this.http.get(url).subscribe((res: any) => {
          for (let v of res.value) {
            if (v.mail) {
              if (v.mail.includes("raksystems.se") && v.givenName !== null) {
                users.push(v);
              }
            }
          }
          if (res["@odata.nextLink"]) {
            console.log(res)
            getUsers(res["@odata.nextLink"]);
          } else {
            resolve(users);
          }
        });
      };

      getUsers(`${this.baseURL}/graph/users`);
    });

    

    // TODO: Remove when custom API is implemented

  getToken = async () =>
    new Promise((resolve, reject) => {

      console.log("Getting token");

      const sub = this.waitingForAuth.subscribe((res) => {
        if (res) {
        } else {
          const tokenRefresh = parseInt(localStorage.tokenRefresh);
          const diff = Date.now() - tokenRefresh;

          if (diff < 3600000) {
            resolve(localStorage.accessToken);
          } else {
            this.waitingForAuth.next(true);

            this.auth.currentUser.then((user) => {
              user.reauthenticateWithPopup(this.provider).then((result) => {
                var credential: any = result.credential;
                // OAuth access and id tokens can also be retrieved:
                var accessToken = credential.accessToken;
                var idToken = credential.idToken;
                localStorage.accessToken = accessToken;
                localStorage.tokenRefresh = Date.now();
                this.waitingForAuth.next(false);
                sub.unsubscribe();
                resolve(accessToken);
              });
            });
          }
        }
      });
    });

/*   withRefresh$ = (url: string) =>
    new Promise((resolve, reject) => {
      const Options = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.accessToken}`,
        }),
      };

      const request = this.http.get(url, Options).subscribe((res: any) => {
        if (res) {
          resolve(res);
        } else {
          this.auth.currentUser.then((user) => {
            user.reauthenticateWithPopup(this.provider).then((result) => {
              console.log(result);
              var credential: any = result.credential;

              // OAuth access and id tokens can also be retrieved:
              var accessToken = credential.accessToken;
              var idToken = credential.idToken;
              localStorage.accessToken = accessToken;
              localStorage.idToken = idToken;
              this.http.get(url, Options).subscribe((res) => {
                resolve(res);
              });
            });
          });
        }
      });
    }); */
}
