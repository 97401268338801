import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gantt-ruler-day',
  templateUrl: './gantt-ruler-day.component.html',
  styleUrls: ['./gantt-ruler-day.component.scss']
})
export class GanttRulerDayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
