<div class="w-full p-8">
    <div class="text-center mb-8">
      <h2
        class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
      >
        Risker i uppdrag
      </h2>
      <p
        class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white"
      >
      {{commonRisk.title}}
      </p>
    </div>

    <div class="w-full">
        <img [src]="commonRisk.imgURL" alt="">
    </div>

    <div class="rounded-lg bg-gray-50 p-6  mb-4">
      <p>
        {{commonRisk.description}}
      </p>
    </div>
    <div class="mt-4 ml-2">
      <p
        class="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 text-center "
      >
        {{commonRisk.subTitle}}
      </p>
    </div>

    <div class="w-full flex mt-4">
      <div class="w-1/2  p-4 mx-1">
        <p
          class="text-lg leading-8 font-extrabold hidden lg:block text-center text-indigo-600"
        >
          Vanligt förekommande arbetsmiljörisker
        </p>
      </div>
      <div class="w-1/2  p-4 mx-1">
        <p
          class="text-lg leading-8 font-extrabold hidden lg:block text-center text-indigo-600 "
        >
          Att tänka på
        </p>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
      <ng-container
        *ngFor="let risk of commonRisk.risks"
        class="w-full flex mt-2 group"
      >
        <div
          class="w-full rounded flex bg-indigo-50 text-gray-800 p-4 mx-1 group-hover:bg-indigo-200"
        >
          <div class="self-center w-full">
            <h2
              class="text-xs block lg:hidden text-indigo-600 font-semibold tracking-wide uppercase"
            >
              Arbetsmiljörisk
            </h2>
            <p class="text-xl font-medium self-center lg:text-center">
              {{ risk.title }}
            </p>
          </div>
        </div>
        <div
          class="w-full rounded border p-4 mx-1 group-hover:bg-indigo-100"
        >
          <h2
            class="text-xs bloc lg:hidden text-indigo-300 font-semibold tracking-wide uppercase"
          >
            Att tänka på
          </h2>
          <p>{{ risk.todo }}</p>
        </div>
      </ng-container>
    </div>

    <div class="mt-8 mb-8 ml-2">
      <p
        class="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 text-center"
      >
        {{commonRisk.subTitle2}}
      </p>
    </div>
    <div
      class="flex mb-4 p-4 rounded bg-gray-50 border"
      *ngFor="let bullet of commonRisk.bullets"
    >
      <div class="flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 mt-1 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="w-full flex">
        <div class="self-center text-base">{{ bullet }}</div>
      </div>
    </div>
  </div>
