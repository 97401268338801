import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gantt-chart-week-header',
  templateUrl: './gantt-chart-week-header.component.html',
  styleUrls: ['./gantt-chart-week-header.component.scss']
})
export class GanttChartWeekHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
