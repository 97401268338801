import { Component, OnInit } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FirebaseService } from "src/app/core/services/firebase.service";
import { StateService } from "src/app/core/services/state.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-create-activity",
  templateUrl: "./create-activity.component.html",
  styleUrls: ["./create-activity.component.scss"],
})
export class CreateActivityComponent implements OnInit {
  currentUser: any;

  activityForm = new FormGroup({
    name: new FormControl("", Validators.required),
    pnumber: new FormControl("", Validators.required),
    customer: new FormControl("", Validators.required),
    size: new FormControl("", Validators.required),
  });

  types: any[];

  constructor(
    private stateService: StateService,
    private firebaseService: FirebaseService,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    this.types = [
      { name: "Litet <100 timmar", value: "standard-small/version3" },
      { name: "Stort >100 timmar", value: "standard-big/version1" },
    ];

    this.stateService
      .returnCurrentUser()
      .subscribe((user) => (this.currentUser = user));
  }

  onSubmit() {

    const createdBy = {
      id: this.currentUser.id,
      displayName: this.currentUser.displayName,
    };

    this.firebaseService
      .createDocument("projects", {
        ...this.activityForm.value,
        created: new Date(),
        createdBy,
        deleted: false
      })
      .then((doc) => {

        const value = this.activityForm.value;

        this.analytics.logEvent('new_project', { projectName: value.name, pNumber: value.pnumber, createdBy });
        this.firebaseService
          .createDocumentPath(
            `${environment.collections.projects}/${doc.id}/participants/${localStorage.graphID}`,
            {
              role: "Uppdragsansvarig",
              manager: true,
              id: localStorage.graphID,
            }
          )
          .then(() => {
            this.router.navigateByUrl(`/projects/project/${doc.id}`);
          });
      });
  }
}
