<div class="max-w-2xl mx-auto">
  <div class="w-full">
    <div class="w-full max-w-7xl mx-auto flex flex-col rounded-lg">
      <div class="w-full">
        <img src="../../../../assets/img/Visual collaboration.svg" alt="" />
      </div>
      <div class="w-full text-center">
        <h1 class="text-3xl heading">
          Välkommen till Systep
          <span
            ><img
              class="inline-block w-12"
              src="../../../../assets/img/icons/icons/favicon.png"
              alt=""
          /></span>
        </h1>
        <p class="text-lg">Vänligen logga in för att fortsätta</p>
      </div>
    </div>
  </div>
</div>
<div class="w-full">
  <div class="max-w-2xl mx-auto">
    <div class="w-full bg-gray-100 p-3 rounded-full shadow-xl mt-3">
      <div class="w-full flex-col mt-3 text-center" *ngIf="!loading">
        <button
          (click)="loginPopup()"
          class="bg-blue-600 text-white mx-auto heading font-semibold w-2/3 p-4 shadow-xl rounded-full"
        >
          Logga in med office 365
        </button>
        <p class="text-xs text-center mt-4">
          Problem att logga in? Kontakta IT-avdelningen
        </p>
      </div>

      <div class="w-full flex-col mt-3" *ngIf="loading">
        <p class="text-center mt-3">
          Samlar in användarinformation, detta kan ta några sekunder
        </p>
        <img
          class="w-80 mx-auto block"
          src="../../../../assets/img/undraw_a_moment_to_relax_bbpa.svg"
          alt=""
        />
        <p class="text-xs text-center mt-4">Ta en liten paus så länge</p>
      </div>

      <div class="w-full flex-col mt-3" *ngIf="showConsentModal">
        <p class="text-center mt-3">
          Du måste godkänna några saker
        </p>

        <a target="_blank" [href]="consentLink"><button (click)="consent()">Godkänn</button></a>
        <img
          class="w-80 mx-auto block"
          src="../../../../assets/img/undraw_a_moment_to_relax_bbpa.svg"
          alt=""
        />
        <p class="text-xs text-center mt-4">Ta en liten paus så länge</p>
      </div>
    </div>
  </div>
</div>
