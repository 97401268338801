import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss'],
})
export class ParticipantsComponent implements OnInit {
  @Input() participants: any[];
  @Input() id: any[];
  @Input() rm: boolean;
  participants$:Observable<any>

  constructor(private firebaseService:FirebaseService) {}

  ngOnInit(): void {
    // Load the participant in the selected project
    this.participants$ = this.firebaseService.getCollectionSnapshotPath(`${environment.collections.projects}/${this.id}/participants`)
  }
}
