import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { GanttService } from 'src/app/core/services/gantt.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';
import { uid } from 'uid';

@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss']
})
export class GanttChartComponent implements OnInit {

  @ViewChild('gantt') gantt: ElementRef;

  monthsToRender = [
    '2021-01',
    '2021-02',
    '2021-03',
    '2021-04',
    '2021-05',
    '2021-06',
  ]

  totalDays: number = 0
  months = []
  scroll: number;
  rows: any []

  daySize: number
  startWeek: number;
  rows$: BehaviorSubject<any>

  rowIds$: Observable<any>

  constructor(private gs: GanttService, private stateService: StateService) { }

  ngOnInit(): void {

    this.stateService.addRow(this.createBlocks())
    this.stateService.addRow(this.createBlocks())
    this.stateService.addRow(this.createBlocks())
    this.stateService.addRow(this.createBlocks())
    this.stateService.addRow(this.createBlocks())

   this.rowIds$ = this.stateService.loadRowsIds()

    this.gs.rowsPromise()
    .then((res) => console.log(res))

    this.getMonths()


    this.gs.daySize.subscribe((size) => {
      this.daySize = size
    })

    this.monthsToRender.forEach((month:string) => {
      this.totalDays = this.totalDays + this.gs.daysInMonth(month);
      this.buildMonth(month, this.gs.daysInMonth(month));
    })  
    


    this.rowsAsync()

  }

  createBlocks() {
    const row = {
      id: uid(),
      name: 'test name',
      blocks: {

      }
    }

    row.blocks[uid()] = {
      title: 'test',
      startDate: '2021-09-01',
      stopDate: '2021-09-20',
      color: 'blue',
      level: 1
    }

    row.blocks[uid()] = {
      title: 'test 2',
      startDate: '2021-08-01',
      stopDate: '2021-08-20',
      color: 'red',
      level: 1
    }

    return row
  
  }


  ngAfterViewInit() {
    this.gantt.nativeElement.scrollLeft += this.scroll;
  }

  buildMonth(month: string, days: number) {

    const d = []

    for (let i = 0; i < days; i++) {
      const day = i+1;
      d.push(day)
    }

    this.months.push({
      month,
      monthName: this.gs.monthName(month),
      year: this.gs.monthYear(month),
      days: d
    })

    //console.log(this.months)

  }

  rowsAsync = async () => {


    const rows = await this.gs.rowsPromise()
    console.log(rows)
  }



  getMonths() {

    const monthsToRender = this.gs.monthsToRender()
    console.log(monthsToRender)
    this.monthsToRender = monthsToRender.months;
    this.scroll = monthsToRender.pixelsToScroll;
    this.startWeek = parseInt(monthsToRender.startWeek)


  }

  zoomIn() {
    this.gs.zoomIn()
  }

  zoomOut() {
    this.gs.zoomOut()
  }

}
