import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, Subscription } from 'rxjs';
import { GraphService } from 'src/app/core/services/graph.service';
import { StateService } from 'src/app/core/services/state.service';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  activities$:Observable<any>
  activities:any
  subscription: Subscription
  projectScope:string = 'my'
  loginDisplay = false;

  constructor(private router:Router, private stateService:StateService, private graph:GraphService, private authService: MsalService, private msalBroadcastService: MsalBroadcastService) { }

  ngOnInit(): void {
   // this.graph.getAllUsers().then((result) => console.log(result))
    this.stateService.loadMyProjects()
    this.stateService.hideSidebar()
    this.stateService.loadUsers()
    this.activities$ = this.stateService.getMyProjects()
    this.subscription = this.activities$.subscribe((activities) => {
      this.activities = activities
    })

  }

 

  openActivity(id:string) {
    this.router.navigateByUrl(`projects/project/${id}`)
  }

  createActivity() {
    this.router.navigateByUrl('projects/create/project')
  }

}
