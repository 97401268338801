import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { GraphService } from '../../services/graph.service';
import { StateService } from '../../services/state.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserUtils, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Location } from "@angular/common";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  provider = new firebase.auth.OAuthProvider('microsoft.com');
  loading:boolean;

  showConsentModal:boolean
  consentLink:string = `https://login.microsoftonline.com/a21320bb-facd-466b-81b1-df45c6bc6325/oauth2/v2.0/authorize?
  client_id=32f92a40-ea15-4bc9-9a51-a2c471579f08
  &redirect_uri=https://systep.se/
  &response_mode=query
  &response_type=code
  &scope=
  https%3A%2F%2Fgraph.microsoft.com%2FUser.ReadBasic.All%20
  &state=12345`

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public auth: AngularFireAuth,
    private router: Router,
    private stateService: StateService,
    private graph: GraphService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private location: Location
  ) {}

  ngOnInit(): void {

    //this.consentModal()

    //TODO: Remove custom parameters when custom API is implemented

    this.provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      tenant: 'a21320bb-facd-466b-81b1-df45c6bc6325',
    });

    this.provider.addScope('user.read')
    this.provider.addScope('User.ReadBasic.All')
    this.provider.addScope('api://32f92a40-ea15-4bc9-9a51-a2c471579f08/access_as_user')

  }


  // Login popup for the MSAL Auth
  loginPopup() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          console.log(response.accessToken)
          this.completeLogin(response);
          this.authService.instance.setActiveAccount(response.account);
        });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {

            this.completeLogin(response);
            this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  // Function for completing the last step of the login
  completeLogin = async (response) => {
    var accessToken = response.accessToken;
    var idToken = response.idToken;
    localStorage.accessToken = accessToken;
    localStorage.tokenRefresh = Date.now();
    console.log(response)
    this.loading = true;
    // this.graph.getProfile()
    // Do a request for custom firebase token
    this.graph.getFirebaseToken().then(async (apiResponse:any) => {
      // Sign in to firebase using the recived token
      this.auth.signInWithCustomToken(apiResponse.token)
      .then((user) => {

        // Store the graph user ID in local storage for later use
        localStorage.graphID = response.uniqueId
        this.router.navigateByUrl('/projects/dashboard');
      })
    })
  }

  

  // TODO: Remove when custom API is implemented
  signInWithO365() {
    this.auth.signInWithPopup(this.provider)
      .then((user:any) => {
            /** @type {firebase.auth.OAuthCredential} */
    var credential = user.credential;

    // OAuth access and id tokens can also be retrieved:
    var accessToken = credential.accessToken;
    localStorage.accessToken = accessToken
    var idToken = credential.idToken;
    localStorage.idToken = idToken;
    localStorage.tokenRefresh = Date.now()
    this.graph.me().subscribe((me:any) => {
      localStorage.graphID = me.id
    })
    console.log({accessToken, idToken})
        this.loading = true
        console.log(user);
        this.stateService.loadUser(user.user.uid);
        localStorage.uid = user.user.uid;
        const sub = this.stateService.returnCurrentUser().subscribe((u) => {
          console.log(u);
          if (u.displayName) {
            if (u.profileInit) {
              sub.unsubscribe()
              this.router.navigateByUrl('/projects/dashboard');
            } else {
              sub.unsubscribe()
              this.router.navigateByUrl('/user/completemyprofile');
            }
          }
        });

      })
      .catch((error) => {
        // Handle error.
      });
  }
}
