<div class="w-64 bg-gray-400 text-center inline-block border-right">JAN</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">FEB</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">MAR</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">APR</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">MAY</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">JUN</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">JUL</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">AUG</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">SEP</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">OCT</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">NOV</div>
<div class="w-64 bg-gray-400 text-center inline-block border-right">DEC</div>
