<div class="max-w-2xl mx-auto">
    <div class="w-full">
        <div class="w-full text-center mt-20">
            <h1 class="text-5xl heading">
              Välkommen till Systep
              <span
                ><img
                  class="inline-block w-12"
                  src="../../../../assets/img/icons/icons/favicon.png"
                  alt=""
              /></span>
            </h1>
          </div>
      <div class="w-full max-w-7xl mx-auto flex flex-col rounded-lg">
        <div class="w-full mt-10">
          <img src="../../../../assets/img/undraw_work_in_progress_uhmv.svg" alt="" />
        </div>
      </div>
      <div class="w-full text-center mt-20">

        <p class="text-lg bg-gray-50 p-4 rounded-lg">Just nu håller vi på att uppdatera lite saker, Vänligen återkom lite senare.</p>
      </div>
    </div>
  </div>
