import { Injectable } from "@angular/core";

import { Store, select } from "@ngrx/store";

import * as projectsActions from "../../ngrx/actions/dryfix.actions";
import * as projectActions from "../../ngrx/actions/project.actions";
import * as userActions from "../../ngrx/actions/user.actions";
import * as myProjectsActions from "../../ngrx/actions/myProjects.actions";
import * as usersActions from "../../ngrx/actions/users.actions";
import * as sidebarActions from "../../ngrx/actions/sidebar.actions";
import * as devicesActions from "../../ngrx/actions/devices.actions";
import * as logActions from "../../ngrx/actions/log.actions";
import * as rowsActions from "../../ngrx/actions/rows.actions";
import * as activitiesActions from "../../ngrx/actions/activities.actions";
import * as mpActions from "../../ngrx/actions/mps.actions";
import * as protocolActions from "../../ngrx/actions/protocol.actions";
import * as fromProjects from "../../ngrx/reducers/dryfix.reducer";
import * as fromUsers from "../../ngrx/reducers/users.reducer";
import * as fromSidebar from "../../ngrx/reducers/sidebar.reducer";
import * as fromDevices from "../../ngrx/reducers/devices.reducer";
import * as fromMyProjects from "../../ngrx/reducers/myProjects.reducer";
import * as fromLogs from "../../ngrx/reducers/log.reducer";
import * as fromActivities from "../../ngrx/reducers/activities.reducer";
import * as fromProject from "../../ngrx/reducers/project.reducer";
import * as fromMps from "../../ngrx/reducers/mps.reducer";
import * as fromProtocols from "../../ngrx/reducers/protocol.reducer";
import * as fromRows from "../../ngrx/reducers/rows.reducer";
import { map, single, mergeMap, filter } from "rxjs/operators";
import { of, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StateService {
  projects: any;
  loading = new BehaviorSubject(false);
  public waitingOnOuth = new BehaviorSubject(false);

  constructor(private store: Store<any>) {}

  returnSidebarState() {
    return this.store.pipe(select("sideBar"));
  }

  hideSidebar() {
    this.store.dispatch(new sidebarActions.Hide());
  }

  showSidebar() {
    this.store.dispatch(new sidebarActions.Show());
  }

  updateSidebar(id: string) {
    this.store.dispatch(new sidebarActions.Update(id));
  }

  addRow(row: any) {
    this.store.dispatch(new rowsActions.Added(row))
  }

  loadRowsIds() {
    return this.store.select(fromRows.selectIds);
  }

  getRow(id:string) {
    return this.store.select(fromRows.selectEntity, { id: id });
  }

  updateBlock(rowID:string, blockIndex:number, block:any) {
    this.store.dispatch(new rowsActions.UpdateBlock(rowID, block, blockIndex))
  }
  

  updateRow(rowID:string, row:any) {
    return this.store.dispatch(new rowsActions.Update(rowID,row))
  }

  getProjects = () => {
    this.store.dispatch(new projectsActions.Query());
    return this.store.select(fromProjects.selectAll);
  };

  getProject = (id: string) => {
    return this.store.select(fromProjects.selectEntity, { id: id });
  };

  loadProject = (id: string) => {
    this.store.dispatch(new projectActions.Query(id));
  };

  loadMps = (protocolID: string) => {
    this.store.dispatch(new mpActions.Query(protocolID));
  };

  loadUser = (id: string) => {
    this.store.dispatch(new userActions.Query(id));
  };

  updateUser = (data: any) => {
    this.store.dispatch(new userActions.Update(data));
  };

  loadUsers = () => {
    this.store.select(fromUsers.selectAll).subscribe((res) => {
      if (res.length === 0) {
        console.log("Dispatch here");
        this.store.dispatch(new usersActions.Query());
      }
    });
  };

  loadDevices = () => {
    this.store.select(fromDevices.selectAll).subscribe((res) => {
      if (res.length === 0) {
        console.log("Dispatch here");
        this.store.dispatch(new devicesActions.Query());
      }
    });
  };

  loadLogs = () => {
    this.store.select(fromLogs.selectAll).subscribe((res) => {
      if (res.length === 0) {
        console.log("Dispatch here");
        this.store.dispatch(new logActions.Query());
      }
    });
  };

  loadBookings = () => {
    console.log("test");

    this.store.select(fromActivities.selectAll).subscribe((res) => {
      if (res.length === 0) {
        console.log("Dispatch here");
        this.store.dispatch(new activitiesActions.Query());
      }
    });
  };

  loadMyProjects = () => {
    console.log("test");

    this.store.select(fromMyProjects.selectAll).subscribe((res) => {
      if (res.length === 0) {
        console.log("Dispatch here");
        this.store.dispatch(new myProjectsActions.Query());
      }
    });
  };

  addActivity = (data: any) => {
    this.store.dispatch(new activitiesActions.Create(data));
  };

  getBookings = () => {
    return this.store.select(fromActivities.selectAll);
  };

  getMyProjects = () => {
    return this.store.select(fromMyProjects.selectAll);
  };

  getBooking = (id: string) => {
    return this.store.select(fromActivities.selectEntity, { id: id });
  };

  getBookingLocation = (location: string) => {
    return this.store.select(fromActivities.selectEntity, {
      location: location,
    });
  };

  getLogs = () => {
    return this.store.select(fromLogs.selectAll);
  };

  getDevices = () => {
    return this.store.select(fromDevices.selectAll);
  };

  getDevice = (id: string) => {
    return this.store.select(fromDevices.selectEntity, { id: id });
  };

  getUser = (id: string) => {
    return this.store.select(fromUsers.selectEntity, { id: id });
  };

  getUsers = () => {
    return this.store.select(fromUsers.selectAll);
  };

  returnCurrentProject() {
    return this.store.pipe(select("currentProject"));
  }

  returnCurrentUser() {
    this.store.pipe(select("user")).subscribe((res) => {
      console.log(res);
      if (!res.id) {
        console.log("Dispatch here");
        this.loadUser(localStorage.graphID);
      }
    });

    return this.store.pipe(select("user"));
  }

  queryMps = (protocolID: string) => {
    //this.store.dispatch(new mpActions.Query(protocolID))
    return this.store.select(fromMps.selectAll);
  };

  queryMpsIds = (protocolID: string) => {
    //this.store.dispatch(new mpActions.Query(protocolID))
    return this.store.select(fromMps.selectIds);
  };

  getMp = (id: string) => {
    return this.store.select(fromMps.selectEntity, { id: id });
  };

  setLoadingState = (state: boolean) => {
    this.loading.next(state);
  };

  queryProtocols = (id: string) => {
    this.store.dispatch(new protocolActions.Query(id));
    return this.store.select(fromProtocols.selectAll);
  };

  getProtocol = (id: string) => {
    return this.store.select(fromProtocols.selectEntity, { id: id });
  };
}
