import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { FirebaseService } from "src/app/core/services/firebase.service";
import { StateService } from "src/app/core/services/state.service";

@Component({
  selector: "app-add-risk",
  templateUrl: "./add-risk.component.html",
  styleUrls: ["./add-risk.component.scss"],
})
export class AddRiskComponent implements OnInit {
  @Output("save") close: EventEmitter<any> = new EventEmitter();
  @Input() id: string;

  constructor(
    private state: StateService,
    private firestore: FirebaseService,
    private analytics: AngularFireAnalytics
  ) {}

  desc: string;
  mitigation: string;
  risk: number;
  consequence: number;
  projectID: string;

  ngOnInit(): void {
    this.state.returnSidebarState().subscribe((res) => {
      console.log(res);
      this.projectID = res.projectID;
      if (this.id) {
        const sub = this.firestore
          .getDocumentValuePathChanges(
            `projects/${res.projectID}/risks/${this.id}`
          )
          .subscribe((risk: any) => {
            this.desc = risk.desc;
            this.mitigation = risk.mitigation;
            this.risk = risk.risk;
            this.consequence = risk.consequence;
          });
      }
    });
  }
  // Save or update the risk
  save() {
    if (this.id) {
      // Update Risk
      this.firestore
        .updateDocumentPath(`projects/${this.projectID}/risks/${this.id}`, {
          desc: this.desc,
          mitigation: this.mitigation,
          risk: this.risk,
          consequence: this.consequence,
          total: this.risk * this.consequence,
        })
        .then(() => {
          this.close.emit(true);
        });
    } else {
      // Create a new risk
      this.firestore
        .createDocument(`projects/${this.projectID}/risks`, {
          desc: this.desc,
          mitigation: this.mitigation,
          risk: this.risk,
          consequence: this.consequence,
          total: this.risk * this.consequence,
        })
        .then(() => {
          this.analytics.logEvent("risk_added", {
            desc: this.desc,
            mitigation: this.mitigation,
            risk: this.risk,
            consequence: this.consequence,
            total: this.risk * this.consequence,
          });
          this.close.emit(true);
        });
    }
  }

  setRisk(value: number) {
    this.risk = value;
  }

  delete() {
    this.firestore
      .deleteDocument(`projects/${this.projectID}/risks/${this.id}`)
      .then(() => {
        this.analytics.logEvent("risk_deleted");
        this.close.emit(true);
      });
  }

  setConsequence(value: number) {
    this.consequence = value;
  }

  enableButton() {
    if (this.desc && this.mitigation && this.consequence && this.risk) {
      return true;
    } else return false;
  }
}
