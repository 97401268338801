import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import { FirebaseService } from 'src/app/core/services/firebase.service';
//import Checklist from '@editorjs/checklist';
import Table from 'editorjs-table';
import SimpleImage from '@editorjs/simple-image';
import ImageTool from '@editorjs/image';
import Checklist from '@editorjs/checklist';
import AnyButton from 'editorjs-button';
import Mention from '@groupher/editor-mention';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss']
})
export class DocumentEditorComponent implements OnInit {
  @ViewChild('vc', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  editor: EditorJS;

  constructor() { }

  

  ngOnInit(): void {

    this.editor = new EditorJS({
      /**
       * Id of Element that should contain the Editor
       */
      holder: 'editorjs',
      placeholder: 'Click here to write a fantastic description for your activity! You can even add pictures!',

      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: {

        mention: {
          class:Mention,
        },

        AnyButton: {
          class: AnyButton,
          inlineToolbar: false,
          config:{
            css:{
              "btnColor": "btn--grey",
              
            }
          }
        },      
        header: Header,
        table: {
          class: Table,
        },
        list: List,
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile:
                'https://us-central1-raksystemsspringboard.cloudfunctions.net/uploadFile', // Your backend file uploader endpoint
              // 'http://localhost:5001/raksystemsspringboard/us-central1/uploadFile', // Your backend file uploader endpoint
              byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
            },
          },
        },
      },
      onChange: () => {
        //this.save.next('changed')
      },
    });

  }

}
