<div class="border h-full rounded-2xl flex w-full p-4 bg-gray-50 relative overflow-hidden my-2 item-center justify-between">
    <div class="w-full flex item-center justify-between flex-col">
        <div class="w-full">
           <div class="flex item-center justify-between mt-3 mb-3">
            <p class="text-gray-800 text-base font-medium">
                {{risk.desc}}
            </p>
            <button (click)="edit = !edit" class="rounded bg-gray-700 p-1 text-white text-xs self-center">
                Redigera
            </button>
        </div> 
        </div>

        <div>
            <p class="text-gray-700 font-semibold text-xs">
                Vad kan vi göra för att minimiera risken?
            </p>
            <p class="text-gray-400 text-xs">
                {{risk.mitigation}}
            </p>
        </div>
        
       
        <div class="flex justify-between mt-3">
            <div class="w-1/3">
                <p class="text-gray-600 font-semibold text-center text-xs">
                    Risk
                </p> 
                <p class="text-gray-700 font-semibold text-center text-base">
                    {{risk.risk}}
                </p> 
            </div>
            <div class="w-1/3">
                <p class="text-gray-600 font-semibold text-center text-xs">
                    Konsekvens
                </p>  
                <p class="text-gray-700 font-semibold text-center text-base">
                    {{risk.consequence}}
                </p> 
            </div>
            <div class="w-1/3">
                <p class="text-gray-700 font-semibold text-center text-xs">
                    Total
                </p>  
                <p class="font-semibold text-center text-base">
                    <span [class]="getClass()">{{risk.total}}</span>
                    
                </p> 
            </div>
        </div>
    </div>
</div>


<div *ngIf="edit" class="w-full flex h-screen z-50 top-0 left-0 fixed backdrop">
    <div class="w-full max-w-2xl flex flex-col mx-auto rounded p-4 bg-white self-center shadow-xl">
        <div class="w-full"><button (click)="edit = false" class="text-xs bg-red-700 mb-4 text-white p-1 rounded uppercase float-right">Stäng</button></div>
        <app-add-risk (save)="edit = false" [id]="risk.id"></app-add-risk>
    </div>
</div>
