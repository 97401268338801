<div class="mt-20 p-2">
    <div class="w-full max-w-6xl">
        <div class="text-lg font-semibold inline-block mr-2">Activity description</div>
        <button class="bg-blue-800 text-white p-2 rounded-full text-xs mb-2 font-semibold">Save changes</button>
        <p class="text-sm leading-tight">Dokumentnamn</p>
        <input class="w-full rounded text-lg p-2 border my-2" placeholder="Document title" type="text">
    </div>
    <div class="w-full max-w-6xl p-3 pl-10 bg-white shadow rounded">
        <div class="w-72"> <img src="../../../../assets/img/Raksystems_logo_sin_RGB.png" alt=""></div>
        <div
        class="w-full max-w-6xl pt-5"
        id="editorjs"
        ></div>
    </div>
</div>
