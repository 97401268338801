<div
  class=" bg-white shadow-lg rounded-lg flex-col overflow-hidden p-2 mb-8"
>
  <div class="w-full p-4">
    <h1 class="text-gray-900 font-bold text-2xl">
      Har du noterat några övriga arbetsmiljörisker?
    </h1>
    <p class="mt-2 text-gray-600 text-sm">
      Har du noterat några risker utöver dom allmäna? Tryck på knappen lägg till
      nedan och lägg till din noterade risk
    </p>
    <button (click)="showCommon = !showCommon"
    class=" text-white bg-blue-700 p-2 text-xs mt-1 font-bold uppercase rounded"
  >
    Visa Allmänna risker
  </button>

    <div class="flex item-center justify-between mt-3">
      <button (click)="newRisk = !newRisk"
        class="px-3 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded"
      >
        Lägg till arbetsmiljörisk
      </button>
    </div>
  </div>




  <app-add-risk *ngIf="newRisk" (save)="newRisk = false"></app-add-risk>

  <div *ngIf="risks$ | async as risks" class="grid grid-cols-2 gap-2 mb-4">

    <app-risk-card-single *ngFor="let risk of risks" [risk]="risk"></app-risk-card-single>

    <p *ngIf="risks.length === 0" class="p-4 text-sm text-gray-700">Det finns inga risker tillagda</p>
    
      </div>
</div>

<app-common-risks *ngIf="showCommon" (close)="showCommon = false"></app-common-risks>
