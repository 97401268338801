import * as actions from '../actions/rows.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { createFeatureSelector, createSelector } from '@ngrx/store'

// Main Data Interface


// Entity adapter

export const rowsAdapter = createEntityAdapter<any>();
export interface State extends EntityState<any> { }

// Deafault data / initial state

const defaultProject = {
    ids: [],
    entities: {}
}

export const initialState: State = rowsAdapter.getInitialState();

export function rowsReducer(
    state: State = initialState,
    action: actions.ProjectActions) {

    switch (action.type) {

        case actions.QUERY:
            return initialState

        case actions.ADDED:
            return rowsAdapter.addOne(action.payload, state)

        case actions.MODIFIED:
            
            return rowsAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state)

        case actions.UPDATE:

        console.log(state)
            
            return rowsAdapter.updateOne({
                id: action.id,
                changes: action.changes
            }, state)
        
        case actions.UPDATE_BLOCK:

            console.log(state)
            

            const updateBlock = (state: State, rowId:string, blockIndex:number, block:any) => {

                let s = Object.assign({}, state)
                s.ids[0] = 'sdsd'

                console.log(s)


                for (let i = 0; i < s.ids.length; i++) {

                    const id = s.ids[i]

                    if (id === rowId) {
                        s.entities[id].blocks[blockIndex] = block
                    }

                }

                return s

            }               
                 return updateBlock(state, action.id, action.blockIndex, action.block)

        case actions.REMOVED:
            return rowsAdapter.removeOne(action.payload.id, state)

        default:
            return state
    }
}


export const getRowsState = createFeatureSelector<State>('rows')

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = rowsAdapter.getSelectors(getRowsState);

export const selectEntity = createSelector(
    selectEntities,
    (entities, props) => entities[props.id]
  );

 /*  export const selectEntityBookingLocation = createSelector(
    selectEntities,
    (entities, props) => {
        entities.location === props.location
    }
  ); */

