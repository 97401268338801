import { Component, Input, OnInit } from '@angular/core';
import { GanttService } from 'src/app/core/services/gantt.service';

@Component({
  selector: 'app-gantt-ruler-days',
  templateUrl: './gantt-ruler-days.component.html',
  styleUrls: ['./gantt-ruler-days.component.scss']
})
export class GanttRulerDaysComponent implements OnInit {

 @Input() monthsToRender: any[]


  totalDays: number = 0
  months = []

  width:string

  constructor(private gs: GanttService) { }

  ngOnInit(): void {



    this.monthsToRender.forEach((month:string) => {
      this.totalDays = this.totalDays + this.gs.daysInMonth(month);
      this.buildMonth(month, this.gs.daysInMonth(month));
    })

  }

  buildMonth(month: string, days: number) {

    const d = []

    for (let i = 0; i < days; i++) {
      const day = i+1;
      d.push(day)
    }

    this.months.push({
      month,
      days: d
    })

    //console.log(this.months)

  }

}
