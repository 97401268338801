import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-risk-card-single',
  templateUrl: './risk-card-single.component.html',
  styleUrls: ['./risk-card-single.component.scss']
})
export class RiskCardSingleComponent implements OnInit {

  @Input() risk:any
  @Output('close') closeEmitter: EventEmitter<any> = new EventEmitter()

  edit: boolean
  totalClass: string

  constructor() { }

  ngOnInit(): void {


    // Set the risk colors

    if (this.risk.total <= 3) this.totalClass = 'text-green-500';
    else if (this.risk.total <= 6) this.totalClass = 'text-yellow-700';
    else if (this.risk.total > 6) this.totalClass = 'text-red-600'; 

  }

  // Return classes for the risk colors
  getClass() {
    if (this.risk.total <= 3) return 'text-green-500';
    else if (this.risk.total <= 6) return 'text-yellow-500';
    else if (this.risk.total > 6) return 'text-red-600'; 
    else return 'text-indigo-100';
  }


 

}
