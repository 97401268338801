import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gantt-chart-month-content-row',
  templateUrl: './gantt-chart-month-content-row.component.html',
  styleUrls: ['./gantt-chart-month-content-row.component.scss']
})
export class GanttChartMonthContentRowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
