import { Component, Input, OnInit } from '@angular/core';

import { articles} from '../budgeting/articles'

@Component({
  selector: 'app-budget-table',
  templateUrl: './budget-table.component.html',
  styleUrls: ['./budget-table.component.scss']
})
export class BudgetTableComponent implements OnInit {

  @Input() budget: any

  articles = articles

 

  constructor() { }

  ngOnInit(): void {
  }

  showArt(key) {
    return this.articles[key]
  }

  rowSum(rows) {
    let r = {}

    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];

      if (r[row.art]) {
        r[row.art] = r[row.art] + row.count
      } else {
        r[row.art] = row.count
      }

    }

    console.log(Object.keys(r))
    console.log(r)
    return {...r, keys: Object.keys(r)}

  }

  sumPriceMeasurement(measurement) {

    let sum = measurement.sum
    let keys = sum.keys
    let price = 0

    for (let i = 0; i < sum.keys.length; i++) {
      price = price + sum[keys[i]] * this.articles[keys[i]].price
    }

    return price
    
  }

}
