<div class="w-full max-w-7xl mx-auto pt-20">
  <p *ngIf="currentUser" class="text-4xl font-bold leading-tight">Skapa ett nytt projekt</p>
  <form [formGroup]="activityForm" (ngSubmit)="onSubmit()">
    <div class="w-full flex flex-col mt-5">
      <div class="w-full my-2">
        <label class="block text-lg font-semibold ml-6 text-gray-700">
          Projektnamn <span class="text-red-500 text-lg">*</span>
        </label>
        <input
          class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
          type="text"
          placeholder="Ange projektets namn"
          formControlName="name"
        />
      </div>
      <div class="w-full my-2">
        <label class="block text-lg font-semibold ml-6 text-gray-700">
          Pnummer <span class="text-red-500 text-lg">*</span>
        </label>
        <input
          class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
          type="text"
          placeholder="Ange projektnummer från pyramid"
          formControlName="pnumber"
        />

      </div>
      <div class="w-full my-2">
        <label class="block text-lg font-semibold ml-6 text-gray-700">
          Kund <span class="text-red-500 text-lg">*</span>
        </label>
        <input
          class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
          type="text"
          formControlName="customer"
        />
      </div>
      <div class="w-full my-2">
        <label class="block text-lg font-semibold ml-6 text-gray-700">
          Omfattning <span class="text-red-500 text-lg">*</span>
        </label>
        <select
          class="block text-3xl rounded-full focus:border-indigo-600 border-2 w-full p-2 pl-4 mt-1"
          type="text"
          formControlName="size"
        >
          <option *ngFor="let type of types" [value]="type.value">
            {{ type.name }}
          </option>
        </select>
      </div>
      <div>
        <button
          class="text-3xl p-2 rounded-full text-white mt-4"
          [class.bg-gray-500]="!activityForm.valid"
          [class.bg-blue-800]="activityForm.valid"
          type="submit"
          [disabled]="!activityForm.valid"
        >
          Skapa projekt
        </button>
      </div>
    </div>
  </form>
</div>
