import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { FirebaseService } from "src/app/core/services/firebase.service";
import { StateService } from "../../../core/services/state.service";

@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
  styleUrls: ["./activities.component.scss"],
})
export class ActivitiesComponent implements OnInit {
  @Input() scope: string;

  filter: string;

  filterForm = new FormGroup({
    filterText: new FormControl(""),
  });

  activities$: Observable<any>;
  p: number = 1;

  constructor(
    private stateService: StateService,
    private router: Router,
    private FirebaseService: FirebaseService
  ) {}

  ngOnInit(): void {
    const myProjects = () => {
      this.stateService.loadMyProjects();
      this.activities$ = this.stateService.getMyProjects();
    };

    const allProjects = () => {
      this.stateService.loadBookings();
      this.activities$ = this.stateService.getBookings();
    };

    if (this.scope === "my") myProjects();
    else allProjects();

    this.filterForm.valueChanges.subscribe((res) => {
      this.filter = res.filterText;
    });


    //this.updateAllInScope()

  }


  updateDoc(id: string) {
    this.FirebaseService.updateDocOnce(id);
  }

  updateAllInScope() {
    setTimeout(() => {
       this.activities$.subscribe((activities: [any]) => {
      console.log(activities)
      activities.forEach((activity: any) => {
        const id = activity.id;
        this.updateDoc(id);
      });
    }).unsubscribe();
    }, 2000)
   
  }

  openActivity(id: string) {
    this.router.navigateByUrl(`projects/project/${id}`);
  }
}
