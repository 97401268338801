<div (click)="closeModal()"
  style="background-color: rgba(0, 0, 0, 0.6);"
  class="w-full h-screen fixed z-50 top-0 left-0 flex"
>
  <div
    class="w-96 h-72 rounded-lg border shadow self-center z-50 mx-auto my-auto bg-white"
  >
    <div class="w-full h-full flex p-4">
      <div class="text-center w-full self-center flex flex-col">
        <img
          class="w-1/2 mx-auto mb-4"
          src="../../../../assets/img/undraw_my_files_swob.svg"
          alt=""
        />
        <ng-container *ngIf="downloadLink">
          <p class="block">Din fil är redo för nedladdning</p>
          <a (click)="closeModal()"
            class="bg-blue-800 hover:bg-blue-900 text-white p-2 rounded-full block"
            [href]="downloadLink"
            >Ladda ner fil</a
          >
        </ng-container>
        <ng-container *ngIf="!downloadLink">
            <p class="block">Genererar din fil...</p>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
          </ng-container>
          <button class="mt-2 text-xs text-red-600 font-semibold p-1 mx-36 rounded-lg hover:bg-red-200">Stäng</button>
      </div>
    </div>
  </div>
</div>
