import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuOpen:boolean = false
  profileMenu:boolean = false

  constructor(private auth: AngularFireAuth, private router: Router) { }


  ngOnInit(): void {
  }

  // Sign out the user and return to login page
  signOut() {
    this.auth.signOut()
    .then(() => {
      this.router.navigateByUrl('/login')
    })

  }

}
