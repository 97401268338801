
import { Action } from '@ngrx/store';

export const QUERY =    '[User] query user'
export const ADD =      '[User] add user'
export const UPDATE =      '[User] update user'

export class Query implements Action {
    readonly type = QUERY;
    constructor(public id:string) {}
}

export class Add implements Action {
    readonly type = ADD;
    constructor(public payload: any) {}
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: any) {}
}

export type ProjectActions = 
Query |
Add |
Update
