import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-common-risks',
  templateUrl: './common-risks.component.html',
  styleUrls: ['./common-risks.component.scss']
})
export class CommonRisksComponent implements OnInit {

  @Output('close') closeEmitter: EventEmitter<any> = new EventEmitter()

  page:string = 'start'

  // TODO: Clean up and move risks to separate files

  commonRisks = {
    rbk: {
      title: 'Arbetsmiljörisker vid genomförande av RBK & GBR-uppdrag',
    description: 'Utförande av fuktmätning i betong och avjämningsmassa och tillhörande arbetsmoment kan medföra vissa arbetsmiljörisker. Detta dokument beskriver vilka övergripande arbetsmiljörisker som kan förekomma vid utförande av RBK- och GBR-uppdrag',
    subTitle: 'Vanligt förekommande arbetsmiljörisker vid utförande av RBK- & GBR-uppdrag',
    subTitle2: 'Förebyggande åtgärder innan RBK-, och GBR arbete påbörjas:',
    imgURL: 'https://images.unsplash.com/photo-1580152216273-81250489f69f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    risks: [
      {title: 'Buller (vid borrning i betong)', todo: 'Använda alltid hörselskydd och skyddsglasögon vid borrning.  '},
      {title: 'Beträda byggarbetsplats ', todo: 'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask)'},
      {title: 'Pågående andra arbeten på byggarbetsplatsen', todo: 'Ta alltid kontakt med platsledning alt arbetsmiljösamordnaren för info om ev eventuella pågående arbeten på byggarbetsplatsen. Ta alltid del av särkerhetsföreskrifterna på arbetsplatsen tex APD-plan, återsamlingsplats, brandskyddsutrustning, larm, mm.'},
      {title: 'Dammspridning', todo: 'Använd alltid sug (dammsugare samt dammuppsamlare) vid borrning i betong och avjämningsmassa. Använd även alltid dammskydd/skyddsmask för mun.'},
      {title: 'Tunga lyft av handverktyg/utrustning', todo: 'Använd pirra om möjligt'},
      {title: 'Ensamarbete', todo: 'Säkerställ att arbetsledning får kännedom om att du är på arbetsplatsen samt att du kommer att säga till när du lämnar arbetsplatsen. (in- och utloggning)'},
      {title: 'Bilkörning ', todo: 'Planera dina uppdrag så att det finns tidsmarginal mellan uppdragen - så att du inte behöver bryta mot trafikleglerna! Kör försiktigt! Säkerställ att det finns förbandslåda i bil. '},
      {title: 'Viberationer i handverktyg', todo: 'Ta pauser emellan åt! '},
      {title: 'Obekväm arbetsställning ', todo: 'Ta pauser emellan åt! (Tänk på att arbeta ergonomiskt), utför pausegympa!'},
    ],
    bullets: [
      'Säkerställ att arbetsplats specifik riskanalys genomförs innan arbete påbörjas. (Vilka specifika risker kan förekomma byggarbetsplatsen?). ',
      'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask, dammsugare vid borrning i betong samt dammuppsamlare) detta tillhandahålls av Raksystems.',
      'Prata med platsledning/byggherre innan arbete påbörjas och hör vilka arbetsmiljörisker som kan förekomma. (Det kanske är andra entreprenörer som arbetar i samma byggnad eller i anslutning och som genomför riskfyllda aktiviteter som kan påverka dig).',
      'Ta del av objektets/byggarbetsplatsens specika skärhetsrutiner och riktlinjer.',
    ]
    },
    ttl: {
      title: 'Arbetsmiljörisker vid genomförande av TTL-uppdrag',
    description: 'Utförande av lufttäthetsuppdrag (TTL) med tillhörande arbetsmoment kan medföra vissa arbetsmiljörisker. Detta dokument beskriver vilka övergripande arbetsmiljörisker som kan förekomma vid utförande av TLL-uppdrag.',
    subTitle: 'Vanligt förekommande arbetsmiljörisker vid utförande av TTL-uppdrag',
    subTitle2: 'Förebyggande åtgärder innan TTL-arbete påbörjas:',
    imgURL: 'https://images.unsplash.com/photo-1580894524720-add657e0ec07?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1847&q=80',
    risks: [
      {title: 'Buller ', todo: 'Vid behov använda hörselskydd'},
      {title: 'Pågående andra arbeten på byggarbetsplatsen', todo: 'Ta alltid kontakt med platsledning alt arbetsmiljösamordnaren för info om ev eventuella pågående arbeten. Ta alltid del av särkerhetsföreskrifterna på arbetsplatsen.'},
      {title: 'Dammspridning', todo: 'Vid behov använd skyddsmask'},
      {title: 'Tunga lyft av utrustning', todo: 'Använd pirra om möjligt'},
      {title: 'Halkrisk på byggarbetsplats', todo: ''},
      {title: 'Risk vid beträdande av hisstak', todo: 'Utbildning krävs. Hisstak får ej beträdas om du inte är utbildad. Följ upprättad rutin för arbete på hisstak.'},
      {title: 'Fallrisk från ställning, stege och liftar', todo: 'Använd godkänd ställning alternativt skylift vid arbete över 1,5 m. Använd alltid personlig fallskyddsutrustning samt säkerställ att det finns kollektiva skyddsanordningar innan arbete påbörjas. Du måste ha dokumterad utbildning för att arbeta på hög höjd).'},
      {title: 'Ensamarbete', todo: 'Säkerställ att arbetsledning/fastighetsägare får kännedom om att du är på arbetsplatsen/fastigheten samt att du kommer att säga till när du lämnar arbetsplatsen. (in- och utloggning). Var alltid två personer vid besök hos privat personer eller om det det finns risk för att fastna, bli instängd, ect.'},
      {title: 'Bilkörning ', todo: 'Planera dina uppdrag så att det finns tidsmarginal mellan uppdragen - så att du inte behöver bryta mot trafikleglerna! Kör försiktigt!'},
    ],
    bullets: [
      'Säkerställ att riskanalys genomförs innan arbete påbörjas, (Vilka specifika risker kan förekomma på denna byggarbetsplats/byggnad?).',
      'Använd skyddsutrustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask, dammsugare) detta tillhandahålls av Raksystems.',
      'Prata med platsledning/byggherre innan arbete påbörjas och hör vilka arbetsmiljörisker (kanske är andra entreprenörer som arbetar i samma fastighet) som kan förekomma.',
      'Ta del av objektets/byggarbetsplatsens specika skärhetsrutiner och riktlinjer.',
    ]
    },
    utr: {
      title: 'Arbetsmiljörisker vid genomförande av utredningsuppdrag ',
    description: 'Utförande av utredningsuppdrag och tillhörande arbetsmoment kan medföra vissa arbetsmiljörisker. Detta dokument beskriver vilka övergripande arbetsmiljörisker som kan förekomma vid utförande av utredningsppdrag.',
    subTitle: 'Vanligt förekommande arbetsmiljörisker vid utförande av utredningsuppdrag',
    subTitle2: 'Förebyggande åtgärder innan utredningsuppdrag påbörjas:',
    imgURL: 'https://images.unsplash.com/photo-1518437390086-d81dcef7eb58?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    risks: [
      {title: 'Buller (vid borrning)', todo: 'Använda alltid hörselskydd och skyddsglasögon vid borrning. (Säkerställ att du kan höra alt se varningsljus vid ev brand).'},
      {title: 'Beträda byggarbetsplats', todo: 'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask)'},
      {title: 'Pågående andra arbeten på byggarbetsplats eller byggnad.', todo: 'Ta alltid kontakt med platsledning alt arbetsmiljösamordnaren för info om ev eventuella pågående arbeten på byggarplatsen eller fastighetsägare. Ta alltid del av särkerhetsföreskrifterna på arbetsplatsen tex APD-plan, återsamlingsplats, brandskyddsutrustning, larm, mm.'},
      {title: 'Ensamarbete', todo: 'Säkerställ att arbetsledning/fastighetsägare får kännedom om att du är på arbetsplatsen/fastigheten samt att du kommer att  säga till när du lämnar arbetsplatsen. (in- och utloggning). Var alltid två personer vid besök hos privat personer eller om det det finns risk för att fastna, bli instängd, ect. '},
      {title: 'Bilkörning ', todo: 'Planera dina uppdrag så att det finns god tidsmarginal mellan uppdragen – så att du inte behöver bryta mot trafikleglerna! Kör försiktigt!'},
      {title: 'Viberationer i handverktyg', todo: 'Ta pauser emellan åt och försök variera arbetsuppgifterna'},
      {title: 'Obekväm arbetsställning ', todo: 'Använd godkänd ställning alt skylift vid arbete över 1,5 m.  Använd alltid personlig fallskyddsutrustning samt säkerställ att det finns kollektiva skyddsanordningar innan arbete påbörjas. Du måste ha dokumterad utbildning för att arbeta på hög höjd).'},
      {title: 'Tunga lyft av handverktyg/utrustning', todo: 'Använd pirra om möjligt '},
      {title: 'Hantering och exponering av farliga ämnen (PCB, asbest)', todo: 'Vid genomförande av miljöinventering kan det förekomma risk för exponering av farliga ämnen – vid tveksamheter använd alltid erfoderlig skyddsutrustning (skyddsmask, mm).'},
    ],
    bullets: [
      'Säkerställ att arbetsplats specifik riskanalys genomförs innan arbete påbörjas. (Vilka specifika risker kan förekomma på denna byggarbetsplats/fastighet?)',
      'Använd skyddsutrustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask, dammsugare) detta tillhandahålls av Raksystems.',
      'Säkerställ att det finns förbandslåda i bil ',
      'Prata med platsledning/byggherre innan arbete påbörjas och hör vilka arbetsmiljörisker (kanske är andra entreprenörer som arbetar i samma fastighet) som kan förekomma.',
    ]
    },
    kon: {
      title: 'Arbetsmiljörisker vid genomförande av konsultuppdrag (allmänt)',
    description: 'Utförande av konsultuppdrag och tillhörande arbetsmoment kan medföra vissa arbetsmiljörisker. Detta dokument beskriver vilka övergripande arbetsmiljörisker som kan förekomma vid utförande av konsultsppdrag.',
    subTitle: 'Vanligt förekommande arbetsmiljörisker vid utförande av konsultuppdrag (allmänt)',
    subTitle2: 'Förebyggande åtgärder för konsultuppdrag (allmänt):',
    imgURL: 'https://images.unsplash.com/photo-1567954970774-58d6aa6c50dc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2089&q=80',
    risks: [
      {title: 'Bilkörning ', todo: 'Planera dina uppdrag så att det finns god tidsmarginal mellan uppdragen – så att du inte behöver bryta mot trafikleglerna! Kör försiktigt!'},
      {title: 'Beträdande av byggarbetsplats ', todo: 'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask)'},
      {title: 'Pågående andra arbeten på byggarbetsplats eller byggnad.', todo: 'Ta alltid kontakt med platsledning alt arbetsmiljösamordnaren för info om ev eventuella pågående arbeten på byggarplatsen eller fastighetsägare. Ta alltid del av särkerhetsföreskrifterna på arbetsplatsen tex APD-plan, återsamlingsplats, brandskyddsutrustning, larm, mm.'},
      {title: 'Ensamarbete', todo: 'Säkerställ att arbetsledning/fastighetsägare får kännedom om att du är på arbetsplatsen/fastigheten samt att du kommer att  säga till när du lämnar arbetsplatsen. (in- och utloggning). Var alltid två personer vid besök hos privat personer eller om det det finns risk för att fastna, bli instängd, ect. '},
      {title: 'Hantera arbetsbelastning, psykisk påfrestning, (stress) ', todo: 'God planering av arbetsuppgifter, prioritera arbetsuppgifterna tillsammans med chef, genomför avstämningar med uppdragsansvarig/chef, ta pauser, hitta stunder för återhämtning. Nyttja friskvård kontinuerligt. '},
      {title: 'Problem med rygg och axlar vid stillasittande arbete, (dator). ', todo: 'Variera arbetsställningen konternuenligt under arbetsdagen, gör gymnastiska övningar. Säkerställ att du sitter rätt på stolen och använd höj-och sänkbart skrivbord. '},
    ],
    bullets: [
      'Nyttja friskvård kontinuerligt, planera & prioritera dina aktiviteter i uppdragen, hitta stunder för återhämtning. ',
      'Säkerställ att arbetsplats specifik riskanalys genomförs innan arbete påbörjas. (Vilka specifika risker kan förekomma på denna byggarbetsplats/fastighet?)',
      'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask, dammsugare vid borrning i betong) - detta tillhandahålls av Raksystems',
      'Säkerställ att det finns förbandslåda i bil,  ',
      'Prata med platsledning/byggherre innan arbete påbörjas och hör vilka arbetsmiljörisker (kanske är andra entreprenörer som arbetar i samma fastighet) som kan förekomma.',
      'Ta del av objektets/byggarbetsplatsens specika skärhetsrutiner och riktlinjer',
    ]
    },
    avf: {
      title: 'Arbetsmiljörisker vid genomförande av avfuktningsuppdrag',
    description: 'Utförande av avfuktningsuppdrag och tillhörande arbetsmoment kan medföra vissa arbetsmiljörisker. Detta dokument beskriver vilka övergripande arbetsmiljörisker som kan förekomma vid utförande av uppdrag.',
    subTitle: 'Vanligt förekommande arbetsmiljörisker vid utförande av avfuktningsuppdrag',
    subTitle2: 'Förebyggande åtgärder för avfuktningsuppdrag:',
    imgURL: 'https://images.unsplash.com/photo-1590516445730-da692ab30f7f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80',
    risks: [
      {title: 'Bilkörning ', todo: 'Planera dina uppdrag så att det finns god tidsmarginal mellan uppdragen – så att du inte behöver bryta mot trafikleglerna! Kör försiktigt!'},
      {title: 'Beträdande av byggarbetsplats ', todo: 'Använd erfoderlig skyddsutustning, tex (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask).'},
      {title: 'Pågående andra arbeten på byggarbetsplats eller byggnad.', todo: 'Ta alltid kontakt med platsledning alt arbetsmiljösamordnaren för info om ev eventuella pågående arbeten på byggarplatsen eller fastighetsägare. Ta alltid del av särkerhetsföreskrifterna på arbetsplatsen tex APD-plan, återsamlingsplats, brandskyddsutrustning, larm, mm.'},
      {title: 'Ensamarbete', todo: 'Säkerställ att arbetsledning/fastighetsägare får kännedom om att du är på arbetsplatsen/fastigheten samt att du kommer att  säga till när du lämnar arbetsplatsen. (in- och utloggning). Var alltid två personer vid besök hos privat personer eller om det det finns risk för att fastna, bli instängd, ect. '},
      {title: 'Buller', todo: 'Använd alltid hörselskydd'},
      {title: 'Obekväm arbetsställning', todo: 'Ta pauser emellan åt! (Tänk på att arbeta ergonomiskt), utför pausegympa!'},
      {title: 'Tunga lyft av handverktyg/utrustning', todo: 'Använd pirra och andra lyfthjälpmedel om möjligt'},
      {title: 'Viberationer i handverktyg', todo: 'Ta pauser emellan åt och om möjligt variera arbetsuppgifterna. '},
      {title: 'Fallrisk från ställning, stege och liftar', todo: 'Använd godkänd ställning alt skylift vid arbete över 1,5 m.  Använd alltid personlig fallskyddsutrustning samt säkerställ att det finns kollektiva skyddsanordningar innan arbete påbörjas. Du måste ha dokumterad utbildning för att arbeta på hög höjd).'},
    ],
    bullets: [
      'Säkerställ att uppdragsspecifik riskanalys genomförs innan arbete påbörjas. (Vilka specifika risker kan förekomma på denna byggarbetsplats/fastighet?)',
      'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask, dammsugare vid borrning i betong) - detta tillhandahålls av Raksystems.',
      'Säkerställ att det finns förbandslåda i bil,  ',
      'Prata med platsledning/byggherre innan arbete påbörjas och hör vilka arbetsmiljörisker (kanske är andra entreprenörer som arbetar i samma fastighet) som kan förekomma.',
      'Ta del av objektets/byggarbetsplatsens specika skärhetsrutiner och riktlinjer.',

    ]
    }
  }

  

  rbkRisks = [
    {title: 'Buller (vid borrning i betong)', todo: 'Använda alltid hörselskydd och skyddsglasögon vid borrning.  '},
    {title: 'Beträda byggarbetsplats ', todo: 'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask)'},
    {title: 'Pågående andra arbeten på byggarbetsplatsen', todo: 'Ta alltid kontakt med platsledning alt arbetsmiljösamordnaren för info om ev eventuella pågående arbeten på byggarbetsplatsen. Ta alltid del av särkerhetsföreskrifterna på arbetsplatsen tex APD-plan, återsamlingsplats, brandskyddsutrustning, larm, mm.'},
    {title: 'Dammspridning', todo: 'Använd alltid sug (dammsugare samt dammuppsamlare) vid borrning i betong och avjämningsmassa. Använd även alltid dammskydd/skyddsmask för mun.'},
    {title: 'Tunga lyft av handverktyg/utrustning', todo: 'Använd pirra om möjligt'},
    {title: 'Ensamarbete', todo: 'Säkerställ att arbetsledning får kännedom om att du är på arbetsplatsen samt att du kommer att säga till när du lämnar arbetsplatsen. (in- och utloggning)'},
    {title: 'Bilkörning ', todo: 'Planera dina uppdrag så att det finns tidsmarginal mellan uppdragen - så att du inte behöver bryta mot trafikleglerna! Kör försiktigt! Säkerställ att det finns förbandslåda i bil. '},
    {title: 'Viberationer i handverktyg', todo: 'Ta pauser emellan åt! '},
    {title: 'Obekväm arbetsställning ', todo: 'Ta pauser emellan åt! (Tänk på att arbeta ergonomiskt), utför pausegympa!'},
  ]

  rbkBullets = [
    'Säkerställ att arbetsplats specifik riskanalys genomförs innan arbete påbörjas. (Vilka specifika risker kan förekomma byggarbetsplatsen?). ',
    'Använd skyddsutustning (skyddsskor, hjälm, hörselskydd, varselväst, handskar, skyddsglasögon, skyddsmask, dammsugare vid borrning i betong samt dammuppsamlare) detta tillhandahålls av Raksystems.',
    'Prata med platsledning/byggherre innan arbete påbörjas och hör vilka arbetsmiljörisker som kan förekomma. (Det kanske är andra entreprenörer som arbetar i samma byggnad eller i anslutning och som genomför riskfyllda aktiviteter som kan påverka dig).',
    'Ta del av objektets/byggarbetsplatsens specika skärhetsrutiner och riktlinjer.',
  ]

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.closeEmitter.emit()
  }

  setPage(page:string) {
    this.page = page
  }

  isPage(page:string) {
    if (page === this.page) return true
  }

  setTitle() {
    if (this.page === 'rbk') return 'vid RBK & GBR-uppdrag';
    if (this.page === 'ttl') return 'vid Lufttäthetsuppdrag';
    if (this.page === 'utr') return 'vid Utredningsuppdrag';
    if (this.page === 'kon') return 'vid Konsultuppdrag';
    if (this.page === 'avf') return 'vid Avfuktningsuppdrag';
  }

}
