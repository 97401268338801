
import { Action } from '@ngrx/store';
import { Project } from '../reducers/dryfix.reducer'

export const QUERY =    '[Rows] query activities'

export const ADDED =    '[Rows] added'
export const MODIFIED = '[Rows] modified'
export const REMOVED =  '[Rows] removed'

export const UPDATE =   '[Rows] update'
export const UPDATE_BLOCK =   '[Rows] update Block'
export const SUCCESS =   '[Rows] update success'
export const CREATE =   '[Rows] create'

export class Query implements Action {
    readonly type = QUERY;
    constructor() {}
}

export class Added implements Action {
    readonly type = ADDED;
    constructor(public payload: Project) {}
}

export class Create implements Action {
    readonly type = CREATE;
    constructor(public payload: any) {}
}

export class Modified implements Action {
    readonly type = MODIFIED;
    constructor(public payload: Project) {}
}

export class Removed implements Action {
    readonly type = REMOVED;
    constructor(public payload: Project) {}
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(
        public id: string,
        public changes: any) {}
}

export class UpdateBlock implements Action {
    readonly type = UPDATE_BLOCK;
    constructor(
        public id: string,
        public block: any,
        public blockIndex: number) {}
}

export class Success implements Action {
    readonly type = SUCCESS;
    constructor() {}
}

export type ProjectActions = 
Query |
Added |
Modified |
Removed |
Update |
UpdateBlock |
Create |
Success;