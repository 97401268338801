import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { GraphService } from '../services/graph.service';

@Injectable()
export class HttpIntercept implements HttpInterceptor {

  constructor(private graph: GraphService) {}

  // TODO: This interceptor can be discarded when the new custom API is implemented

  /* intercept = (req: HttpRequest<any>, next: HttpHandler) => {
    // Get the auth token from the service.

   
    const authToken = this.graph.getToken();
    console.log(authToken)

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${localStorage.accessToken}`)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  } */

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    // if your getAuthToken() function declared as "async getAuthToken() {}"
    //console.log("Getting token");
    let t
     await this.graph.getToken().then((token) => {
       t = token
    })

    // if your getAuthToken() function declared to return an observable then you can use
    // await this.auth.getAuthToken().toPromise()

    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${t}`)
    });

    // Important: Note the .toPromise()
    return next.handle(authReq).toPromise()
  }
}
