import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  @Input() company:string
  companyText:string

  constructor() { }

  ngOnInit(): void {

    if (this.company === 'dry-it') this.companyText = 'Raksystems Dry-IT'
    if (this.company === 'indipendia') this.companyText = 'Indipendia Group'
    if (this.company === 'projektledarhuset') this.companyText = 'Projektledarhuset'

  }

}
