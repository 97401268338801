<div class="" *ngIf="block">
<ng-container *ngIf="header">
    <h1 [class]="class">{{text}}</h1>
</ng-container>
<ng-container *ngIf="paragraph">
    <div class="w-full rounded-lg bg-gray-50 p-2 mt-1">
        <p class="text-gray-900 text-base my-1 mb-3">{{text}}</p>
    </div>
    
</ng-container>

<ng-container *ngIf="customElement">
    <app-risk-card *ngIf="customElementName === '#RISK#'"></app-risk-card>
</ng-container>

<ng-container *ngIf="checklist">
    <div class="my-5">
       <app-check-block *ngFor="let item of checklistItems; let i = index" [checkpoint]="item" [blockIndex]="blockIndex" [checkIndex]="i"></app-check-block>
 
    </div>
</ng-container>
<ng-container *ngIf="anyButton">
    <button (click)="openLink = true" class="text-sm p-2 my-1 shadow bg-blue-800 hover:bg-gray-900 text-white rounded-full cursor-pointer">{{text}}</button>
    <app-document-download (close)="openLink = false" *ngIf="openLink" [link]="link"></app-document-download>
</ng-container>
</div>


