<div class="flex flex-col">
  <form [formGroup]="filterForm">
    <input
      class="w-full text-lg rounded p-1 pl-4 border mb-2"
      placeholder="Filtrera projekt"
      type="text"
      formControlName="filterText"
    />
  </form>
  <div
    *ngIf="activities$ | async as activities"
    class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
  >
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <div
          *ngIf="activities.length === 0"
          class="w-full text-center p-2 text-lg"
        >
          Du har inga registrerade projekt. Tryck på knappen ovan och registrera
          ditt första!
        </div>

        <table
          *ngIf="activities.length > 0"
          class="min-w-full divide-y divide-gray-200"
        >
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Projektnamn
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Uppdragsansvarig
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Deltagare
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              (click)="openActivity(activity.id)"
              *ngFor="
                let activity of activities
                  | filterBy: ['name', 'pnumber']:filter
                  | paginate: { itemsPerPage: 15, currentPage: p }
              "
              class="bg-white hover:bg-blue-100"
            >
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="flex-shrink-0 self-center">
                    <span
                      class="text-xs rounded-full bg-blue-300 p-1 text-blue-900 font-semibold"
                      >{{ activity.pnumber }}</span
                    >
                  </div>
                  <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                      {{ activity.name }}
                    </div>
                    <div class="text-sm text-gray-500">
                      {{ activity.customer }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="text-sm text-gray-900 flex items-center">
                  <app-participant
                    [size]="'sm'"
                    [participant]="activity.manager"
                  ></app-participant>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap">
                <div class="flex">
                  <app-participant
                    [size]="'sm'"
                    *ngFor="let participant of activity.participants"
                    [participant]="participant"
                  ></app-participant>
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <app-status [activity]="activity"></app-status>
              </td>
              <td
                class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium"
              ></td>
            </tr>
            <!-- More people... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="mx-auto text-gray-800 mt-3">
    <pagination-controls
      class="my-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div>
</div>
