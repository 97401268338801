<div class="w-full mt-20">
  <div class="w-full max-w-7xl mx-auto flex-col shadow-xl rounded-full md:shadow-none hidden md:block pt-4">
    <h1 class="text-5xl heading text-center hidden md:block">
        Välkommen till Systep
        <span
          ><img
            class="inline-block w-12"
            src="../../../../assets/img/icons/icons/favicon.png"
            alt=""
        /></span>
      </h1>
      <div class="w-full flex max-w-5xl">
           <div class="p-4 rounded-lg hidden md:block">

      <img
      class=" mx-auto mb-4 ml-4"
        src="../../../../assets/img/Infrastructure Development.svg"
        alt=""
      />
    </div>
    <div class="self-center rounded-full p-4">
        <p class="text-3xl heading text-blue-900 mb-2">Utveckling pågår</p>
              <p class="text-xl text-gray-700 heading leading-snug">
        Systep är ett system utvecklat för och av Raksystems Dry-IT och finns
        för att hjälpa oss i vår vardag som konsulter. Systep är ett levande
        system som ständigt är under utveckling. Nya funktioner kommer därför
        dyka upp under resans gång.
      </p>
      <p class="mt-4 heading text-blue-800 font-semibold">
        Har du tankar och ider om hur vi kan förbättra systep? Tveka inte att
        höra av dig.
      </p>
    </div>
      </div>
   
    
  </div>
  <div class="md:hidden">
    <div class="self-center rounded-full p-4">
        <p class="text-xl heading text-blue-900 mb-2">Utveckling pågår</p>
              <p class="text-base text-gray-700 heading leading-snug">
        Systep är ett system utvecklat för och av Raksystems Dry-IT och finns
        för att hjälpa oss i vår vardag som konsulter. Systep är ett levande
        system som ständigt är under utveckling. Nya funktioner kommer därför
        dyka upp under resans gång.
      </p>
      <p class="mt-4 heading text-blue-800 font-semibold">
        Har du tankar och ider om hur vi kan förbättra systep? Tveka inte att
        höra av dig.
      </p>
    </div>
  </div>
</div>
