import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
  styleUrls: ['./document-download.component.scss']
})
export class DocumentDownloadComponent implements OnInit {

  @Input() link:string;
  id:string

  @Output() close:EventEmitter<any> = new EventEmitter()
  data$:Observable<any>
  downloadLink:string

  constructor(private fns: AngularFireFunctions, private stateService: StateService) { }

  ngOnInit(): void {

    this.stateService.returnSidebarState()
    .subscribe((state) => {
      console.log({id: state.projectID})
      console.log({link: this.link})
      // Call Firebase function to generate a document
      const callable = this.fns.httpsCallable('generate');
      this.data$ = callable({ id: state.projectID, link: this.link });
  
      // Return the download link to the user
      this.data$.subscribe((res) => {
        this.downloadLink = res.downloadURL
      })
    })



  }

  closeModal() {
    setTimeout(() => {
      this.close.emit()
    }), 1000
  }
  

}
