import * as actions from '../actions/log.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { createFeatureSelector, createSelector } from '@ngrx/store'

// Main Data Interface


// Entity adapter

export const logAdapter = createEntityAdapter<any>();
export interface State extends EntityState<any> { }

// Deafault data / initial state

const defaultProject = {
    ids: [],
    entities: {}
}

export const initialState: State = logAdapter.getInitialState();

export function logReducer(
    state: State = initialState,
    action: actions.ProjectActions) {

    switch (action.type) {

        case actions.QUERY:
            return initialState

        case actions.ADDED:
            return logAdapter.addOne(action.payload, state)

        case actions.MODIFIED:
            return logAdapter.updateOne({
                id: action.payload.id,
                changes: action.payload
            }, state)

        case actions.REMOVED:
            return logAdapter.removeOne(action.payload.id, state)

        default:
            return state
    }
}

export const getLogState = createFeatureSelector<State>('logs')

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = logAdapter.getSelectors(getLogState);

export const selectEntity = createSelector(
    selectEntities,
    (entities, props) => entities[props.id]
  );
