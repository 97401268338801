import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  sidebar:boolean
  projectID:string
  project$:Observable<any>

  constructor(private stateService: StateService) { }

  ngOnInit(): void {

    // Load the state of the sidebar
    this.stateService.returnSidebarState()
    .subscribe((state) => {
      console.log(state)
      // Set if the sidebar should be visible or not
      this.sidebar = state.show;
      if (state.projectID) {
        this.projectID = state.projectID;
        this.project$ = this.stateService.getBooking(state.projectID)
      }
      
    })
  }

}
