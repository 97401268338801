import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { FirebaseService } from "src/app/core/services/firebase.service";
import { GraphService } from "src/app/core/services/graph.service";
import { StateService } from "src/app/core/services/state.service";
import {environment} from '../../../../environments/environment'

@Component({
  selector: "app-user-block",
  templateUrl: "./user-block.component.html",
  styleUrls: ["./user-block.component.scss"],
})
export class UserBlockComponent implements OnInit {
  imageToShow: any;
  sign:string
  show:boolean
  currentProjectID: string

 circleClass:string = 'circle flex'

  @Input() user: any;
  @Input() size: string;
  @Input() rm: boolean;
  @Output() selectedUser:EventEmitter<any> = new EventEmitter()

  constructor(private graph: GraphService, private stateService:StateService, private firebaseService:FirebaseService) {}

  private handleError(error: HttpErrorResponse) {
    return of(false);
  }

  ngOnInit(): void {
    this.stateService.returnSidebarState()
    .subscribe((state) => {
      this.currentProjectID = state.projectID
    })

    this.setRandomColor()


    console.log(this.user)

    //this.text()

    /* this.graph
      .getImage(
        `https://graph.microsoft.com/v1.0/users/${this.user.id}/photo/$value`
      )
      .pipe(catchError(this.handleError))
      .subscribe((res) => {
        this.createImageFromBlob(res);
      }); */
  }

  // Generate a random int to randomize colors
  getRandomInt() {
    return Math.floor(Math.random() * 5);
  }

  // Set random color classes for the user-circles
  setRandomColor() {

    const random = this.getRandomInt()

    if (random === 1) this.circleClass = 'circle flex bg-blue-200';
    else if (random === 2) this.circleClass = 'circle flex bg-red-300';
    else if (random === 3) this.circleClass = 'circle flex bg-green-200';
    else if (random === 4) this.circleClass = 'circle flex bg-indigo-200';
    else this.circleClass = 'circle flex bg-purple-200';
  }


  // Generate a image from the blob returned by the GRAPH API
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.imageToShow = reader.result;
      },
      false
    );

    if (image) {
      console.log("image");
      reader.readAsDataURL(image);
    }
  }

  select() {
    this.selectedUser.emit(this.user)
  }

  // Remove participant from project
  removeParticipant() {

    this.firebaseService.deleteDocument(`${environment.collections.projects}/${this.currentProjectID}/participants/${this.user.id}`)
    .then(() => console.log('participant removed'))
  }
  


  // Generate the User initials
  text() {
    
    let displayName = this.user.displayName;
    let split = displayName.split(' ');

    let first = split[0].substr(0, 1)
    let last = split[1].substr(0, 1)
    

    return first + last

  }

}
