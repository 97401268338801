import { Component, Input, OnInit } from '@angular/core';
import {smallV3, smallV2, smallV1, bigV1 } from './block-map';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  @Input() activity:any

  show:boolean
  status: any

  constructor() { }

  ngOnInit(): void { 
    console.log(this.activity)
    this.checkStatus()
  } 

  checkStatus() {  

    if (this.activity.size === 'standard-small/version3') this.status = this.statusMap(smallV3)
    if (this.activity.size === 'standard-small/version2') this.status = this.statusMap(smallV2)
    if (this.activity.size === 'standard-small/version1') this.status = this.statusMap(smallV1)
    if (this.activity.size === 'standard-big/version1') this.status = this.statusMap(bigV1)
  } 

  statusMap(blockMap:any) { 

    return {
      regPyr: this.check(blockMap.regPyr),
      folderCreated: this.check(blockMap.folderCreated),
      confirmation: this.check(blockMap.confirmation),
      closure: this.check(blockMap.closure),
    }

  } 

  check(map:number[]) {
    if (this.activity.checkBlocks) {
      if (this.activity.checkBlocks[map[0]]) { 
        console.log('found')
        if (this.activity.checkBlocks[map[0]][map[1]]) {
          console.log('found2')
          if (this.activity.checkBlocks[map[0]][map[1]].checked) {
            console.log(this.activity.checkBlocks[map[0]][map[1]].checked)
            return this.activity.checkBlocks[map[0]][map[1]].checked
          } else return false
        } else return false
      } else return false
    } else return false
  } 

}
