import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'curency'
})
export class CurencyPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {

    let valueString = value.toString()

    if (valueString.length === 5) {
      return `${valueString.substr(0, 2)} ${valueString.substr(2)}`
    } else if (valueString.length === 6) {
      return `${valueString.substr(0, 3)} ${valueString.substr(3)}`
    }
    else if (valueString.length === 7) {
      return `${valueString.substr(0, 1)} ${valueString.substr(1, 3)} ${valueString.substr(4)}`
    }
    
    else return valueString

  }

}
