import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { map, switchMap, mergeMap, mergeMapTo, flatMap } from 'rxjs/operators';

import { of } from 'rxjs';
import {environment} from '../../../environments/environment'
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private fireStore: AngularFirestore, private auth: AngularFireAuth, private router: Router, private storage: AngularFireStorage) { }

  //FIXME: This file is in desperate need for cleaning up and refactoring

  getCollectionSnapshot = (collection: string) => {
    const colRef = this.fireStore.collection(collection)
    return colRef.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, collection, ...data };
        })
      )
    );
  }

  getCollectionSnapshotPath = (path: string) => {
    const colRef = this.fireStore.collection(path)
    return colRef.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, path, ...data };
        })
      )
    );
  }

  deleteDocument(path:string) {
    return this.fireStore.doc(path).delete()
  }

  getDocumentValueChanges = (collection: string, document: string) => {
    return this.fireStore
      .collection(collection)
      .doc(document)
      .valueChanges()
      .pipe(
        map((response: any) => {
          return { ...response, id: document, collection }
        })
      );
  }

  softDeleteProject(id:string) {
    return this.fireStore.collection('projects').doc(id).update({deleted: true})
  }

  getDocumentValuePathChanges = (path) => {
    return this.fireStore
      .doc(path)
      .valueChanges()
      .pipe(
        map((response: any) => {
          return { ...response }
        })
      );
  }

  getDocument = (documentPath: string) => {
    return this.fireStore
      .doc(documentPath)
      .valueChanges()
      .pipe(
        map((response: any) => {
          return { ...response }
        })
      );
  }

  getUser = (id: string) => {
    return this.fireStore.doc(`users/${id}`).get()
  }

  getBookings = (id: string) => {
    return this.fireStore.collection('bookings', (ref) => ref.where('userID', '==', id))
  }


  getPasswords = (id: string) => {
    return this.fireStore.collection('passwords', (ref) => ref.where('userID', '==', id))
  }

  getTimeslot = (id :string) => new Promise((resolve, reject) => {
    this.fireStore.doc(`timeslots/${id}`).get().subscribe(timeslot => {
      if (timeslot) {
        resolve(timeslot.data())
      }
      else {
        reject('No data')
      }
      
    })
  })


  updateBooking(bookingID: string, slotID: string, data) {
    this.fireStore.collection('bookings')
      .doc(bookingID)
      .update(data)
      .then(() => {
        console.log('booking updated')
      })
  }

 timeSlotsStatus = () => new Promise((resolve, reject) => {

  let status = {}

  this.getCollectionSnapshot('bookings')
  .subscribe((t:any) => {
    status = {}
   for (let i = 0; i < t.length; i++) {
     const booking = t[i]
    if (booking.slotID) {
      if (status[booking.slotID]) status[booking.slotID]++
      else status[booking.slotID] = 1
    }
   }
   resolve(status)
  })
 })


 updateDoc = (path, data) => {

  return this.fireStore.doc(path).update(data)

 }



  addDevice = async (device: any) => {

    const addDevice$ = await this.fireStore
      .collection('devices')
      .doc(device.hostName)
      .set(device)
      .then(() => {
        this.fireStore.doc(`tasklists/dOxH6P9ntWiceXoaDmeG/entities/${device.hostname}`)
        .set({type: 'device'})
        .then(() => console.log('Tasklist updated.'))
      })

    return addDevice$
  }

  getUsers() {
    return this.fireStore.collection<any>('users').stateChanges()
  }
  
  getBookings$() {
    console.log('test2')
    return this.fireStore.collection<any>('bookings').stateChanges()
  }

  //ref => ref.where('deleted', '!=', true)

  getActivities$() {
    console.log('test2')
    return this.fireStore.collection<any>(environment.collections.projects, ref => ref.where('deleted', '!=', true)).stateChanges()
  }

  updateDocOnce(id:string) {
    this.fireStore.collection(environment.collections.projects).doc(id).update({deleted: false})
    .then(() => console.log('doc updated'))
  }

  getDevices$() {
    return this.fireStore.collection<any>('devices').stateChanges()
  }

  addCustomMessage = (message: string) => {
    const data = {
      message,
      type: 'types.message',
      timestamp: Date.now(),
      collection: 'messages',
      parentID: 'NA'
    }

    this.log(data);
  }


  log = async (data: any) => {

    console.log('adding log!')

    const logItem = { ...data, user: localStorage.displayName }

    const addLog$ = await this.fireStore
      .collection('systemLog')
      .add(logItem)

    return addLog$

  }

  uploadImage = (image) => new Promise((resolve, reject) => {
    console.log(image)
    resolve({sucess: true})
  })

  addCustomLog = (data:any) => {
    this.fireStore.collection('systemLog').add(data)
    .then(() => console.log('logg added'))
  }

  signOut() {
    this.auth.signOut()
      .then(() => {
        this.router.navigateByUrl('')
      })

  }

  addDocObservable = async (collection: string, data: any) => {
    return this.fireStore.collection(collection).add(data)
    .then((doc) => {
      return of(doc)
    })
  }


  getAllLogs$ = () => {
    return this.fireStore.collection('systemLog', ref => ref.orderBy('timestamp', 'desc').limit(20)).stateChanges()
  }

  getMyProjects = () => {
    console.log('getting projects') 
    console.log(environment.collections.projects)
    console.log(localStorage.graphID)
    return this.fireStore.collection(environment.collections.projects, ref => ref.where('participants', 'array-contains', localStorage.graphID).where('deleted', '!=', true))
    .stateChanges()

  }

  getLogs$ = (ID: string) => {
    return this.fireStore.collection('systemLog', ref => ref.where('parentID', '==', ID).orderBy('timestamp', 'desc').limit(5)).valueChanges()

  }


  getDevices = () => {
    return this.fireStore.collection('devices').valueChanges()
  }

  createNote = (data: any, parent: any) => {
    return this.fireStore.collection('notes').add(data)

  }

  createDocument = (collection: string, data:any) => {
    return this.fireStore.collection(collection).add(data)
  }

  createDocumentPath = (path: string, data:any) => {
    return this.fireStore.doc(path).set(data)
  }

  updateDocument = (collection:string, doc:string, data:any) => {
    return this.fireStore.collection(collection).doc(doc).update(data)
  }

  updateDocumentPath = (path:string, data:any) => {
    return this.fireStore.doc(path).update(data)
  }
  addTasklist = (taskID:string, deviceID:string) => {
    return this.fireStore
    .collection('devices')
    .doc(deviceID)
    .update({
      tasklists: firebase.firestore.FieldValue.arrayUnion(taskID)
    })
  }

  addParticipant = (activityID:string, user: any) => {
    return this.fireStore
    .collection('activities')
    .doc(activityID)
    .update({
      participants: firebase.firestore.FieldValue.arrayUnion(user)
    })
  }

  removeParticipant = (activityID:string, uid: string) => {
    return this.fireStore
    .collection('activities')
    .doc(activityID)
    .update({
      participants: firebase.firestore.FieldValue.arrayRemove(uid)
    })
  }

  removeTaskList = (taskID:string, deviceID:string) => {
    return this.fireStore
    .collection('devices')
    .doc(deviceID)
    .update({
      tasklists: firebase.firestore.FieldValue.arrayRemove(taskID)
    })
  }

  createNoteAsync = async (data, parentID: any) => {
    this.fireStore.collection('notes').add({ ...data, updatedBy: localStorage.displayName })
      .then((note) => {

        const setType = (collection: string) => {
          if (collection === 'devices') return 'device'
        }

      })

  }

  updateNote = (data: any, ID: string) => {
    return this.fireStore.collection('notes').doc(ID).update(data)

  }

  updateNoteAsync = async (data: any, ID: string, parent: any) => {

    console.log(parent)

    const setType = (collection: string) => {
      if (collection === 'devices') return 'device'
    }

    const request = await this.fireStore.collection('notes').doc(ID).update({ ...data, updatedBy: localStorage.displayName }).then((note: any) => {

    })

    return request
  }

  getNote = (id: string) => {
    return this.fireStore.collection('notes', (ref) => ref.where('parentID', '==', id))
      .snapshotChanges().pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }



}
