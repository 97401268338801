import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import { FirebaseService } from 'src/app/core/services/firebase.service';
//import Checklist from '@editorjs/checklist';
//import Table from '@editorjs/table';
import SimpleImage from '@editorjs/simple-image';
import ImageTool from '@editorjs/image';
import Checklist from '@editorjs/checklist';
import AnyButton from 'editorjs-button'
import { FormControl, FormGroup } from '@angular/forms';

import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  @ViewChild('vc', { read: ViewContainerRef }) viewContainer: ViewContainerRef;

  id: string;
  activity$: Observable<any>;
  data: any;
  participants:any[] = []

  editor: EditorJS;
  blocks: any;
  manager:string
  size:string

  selectedUser:any;

  userRoleForm = new FormGroup({
    role: new FormControl(''),
    manager: new FormControl(false)
  })

  pickUser:boolean;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit(): void {
    this.stateService.loadBookings();
    this.stateService.loadUsers();
    this.id = this.route.snapshot.paramMap.get('id');
    this.activity$ = this.stateService.getBooking(this.id);
    this.stateService.updateSidebar(this.id);
    this.stateService.showSidebar()


    /* this.editor = new EditorJS({
     
      holder: 'editorjs',
      placeholder: 'Click here to write a fantastic description for your activity! You can even add pictures!',

     
      tools: {

        AnyButton: {
          class: AnyButton,
          inlineToolbar: false,
          config:{
            css:{
              "btnColor": "btn--grey",
              
            }
          }
        },      
        header: Header,
        list: List,
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile:
                'https://us-central1-raksystemsspringboard.cloudfunctions.net/uploadFile', // Your backend file uploader endpoint
              // 'http://localhost:5001/raksystemsspringboard/us-central1/uploadFile', // Your backend file uploader endpoint
              byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
            },
          },
        },
      },
      data: this.data,
      onChange: () => {
        //this.save.next('changed')
      },
    }); */


    this.activity$.subscribe((res) => {
      if (res) {
        if (res.fullDescription) {
          this.data = res.fullDescription.blocks;
        }
        if (res.participants) {
          this.participants = res.participants
        }
        if (res.manager) {
          this.manager = res.manager
        }
        this.size = res.size

        this.firebaseService.getDocumentValuePathChanges(`core/checklists/${res.size}`)
        .subscribe((checklist) => {
          console.log(checklist.blocks)
          //this.editor.render(checklist.blocks)
          this.blocks = checklist.blocks;
          console.log(this.blocks)
        })

       /*  this.editor.isReady.then(() => {
          this.firebaseService.getDocumentValuePathChanges(`core/checklists/${res.size}`)
          .subscribe((checklist) => {
            console.log(checklist.blocks)
            this.editor.render(checklist.blocks)
            this.blocks = checklist.blocks;
            console.log(this.blocks)
          })
         }) */

      }
    });




      /*       if (this.parentID) {
        this.notes = this.firebaseService.getNote(this.parentID)
          .subscribe((notes: any) => {
            console.log(notes)
            if (notes.length > 0) {
              this.editor.render(notes[0].data)
              this.id = notes[0].id
              this.lastUpdate = notes[0].data.time
              this.updatedBy = notes[0].updatedBy
              this.notes.unsubscribe()
            }
          })
      } */

    /*    this.editor.isReady.then(() => {
      if (this.parentID) {
        this.notes = this.firebaseService.getNote(this.parentID)
          .subscribe((notes: any) => {
            console.log(notes)
            if (notes.length > 0) {
              this.editor.render(notes[0].data)
              this.id = notes[0].id
              this.lastUpdate = notes[0].data.time
              this.updatedBy = notes[0].updatedBy
              this.notes.unsubscribe()
            }
          })
      }
    }); */
  }

  // Function to set input value for role when checking manager box
  setRole() {
    this.userRoleForm.patchValue({role: 'Uppdragsansvarig'})
  }

  save() {
    this.editor.save().then((blocks) => {
      console.log(blocks);
      this.firebaseService
        .updateDoc(`activities/${this.id}`, { fullDescription: blocks })
        .then(() => console.log('description updated'));
    });
  }

  saveStandard() {
    this.editor.save().then((blocks) => {
      console.log(blocks);
      this.firebaseService.createDocumentPath(`core/checklists/standard-small/version3`, {blocks})
    });
  }

  upload(file) {
    return this.firebaseService.uploadImage(file);
  }

  owner(id) {
    if (id === localStorage.uid) return true;
  }

  join(user:any) {
    
    if (!this.participants.includes(localStorage.uid)) {
      this.firebaseService.addParticipant(this.id, localStorage.uid)
    }
  }

  addParticipant(user:any) {
    this.selectedUser = undefined;
    this.selectedUser = user;
    this.pickUser = false;
  }

  onSubmit() {
    //this.firebaseService.addParticipant(this.id, {id: this.selectedUser.id, role: this.userRoleForm.value.role})

    this.firebaseService.createDocumentPath(`${environment.collections.projects}/${this.id}/participants/${this.selectedUser.id}`, {id: this.selectedUser.id, ...this.userRoleForm.value})
    this.selectedUser = undefined;
  }


  cancel() {
    this.firebaseService.removeParticipant(this.id, localStorage.uid)
  }

  isParticipant() {
    if (this.participants.includes(localStorage.uid)) return true
  }
}
