import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface LenghtData {
  dayLenghtPx: number
  daysInMonth: number
}

interface LengthOutput {
  weekLenghtPx: number
  monthLenghtPx: number
}

@Injectable({
  providedIn: 'root'
})




export class GanttService {

  blocks = [

    {
      title: 'test',
      startDate: '2021-09-01',
      stopDate: '2021-09-20',
      color: 'blue',
      level: 1
    },
    {
      title: 'test 2',
      startDate: '2021-08-01',
      stopDate: '2021-08-20',
      color: 'red',
      level: 2
    }
  ]

  rows = [
    {
      blocks: [

        {
          title: 'test',
          startDate: '2021-09-01',
          stopDate: '2021-09-20',
          color: 'blue',
          level: 1
        },
        {
          title: 'test 2',
          startDate: '2021-08-01',
          stopDate: '2021-08-20',
          color: 'red',
          level: 2
        }
      ]
    },
    {
      blocks: [

        {
          title: 'test',
          startDate: '2021-09-01',
          stopDate: '2021-09-20',
          color: 'blue',
          level: 1
        },
        {
          title: 'test 2',
          startDate: '2021-08-01',
          stopDate: '2021-08-20',
          color: 'red',
          level: 2
        }
      ]
    }
  ]

  blocks$: BehaviorSubject<any> = new BehaviorSubject(this.blocks)
  rows$: BehaviorSubject<any> = new BehaviorSubject(this.rows)
  dayPx = 26
  daySize: BehaviorSubject<any> = new BehaviorSubject(this.dayPx)

  firstRederedDay: string;
  

  constructor() { }

  calculateWeekLenght(dayLenghtPx: number) {
    return dayLenghtPx * 7
  }

  rowsPromise = () => new Promise((resolve, reject) => {
    this.rows$.subscribe((rows) => {
      resolve(rows)
    })
  })

  calculateMonthLenght(dayLenghtPx: number, month: string) {
    const days = moment(month, 'YYYY-MM').daysInMonth();
    return days * dayLenghtPx
  }

  monthsToRender() {

    const months = []

    for(let t = 6; t > 0; t--) {
      const month = moment().subtract(t, 'M').format('YYYY-MM')
      months.push(month)
    }

    for(let t = 0; t <= 12; t++) {
      const month = moment().add(t, 'M').format('YYYY-MM')
      months.push(month)
    }


    const currentMonth = moment(new Date()).format('YYYY-MM');

    let daysToScroll = 0
    
    for (let i = 0; i < months.length; i++) {
      const month = months[i]
      if (month === currentMonth) break
      else {
        daysToScroll = daysToScroll + this.daysInMonth(month)
      }
    }

    this.firstRederedDay = months[0] + '-01'

    return {
      months,
      daysToScroll,
      pixelsToScroll: (daysToScroll * this.dayPx) - 5,
      startWeek: moment(months[0] + '-01').format('W')
    }

  }


  daysInMonth(month:string) {
    return moment(month, 'YYYY-MM').daysInMonth();
  }

  calculateIndex(date:string) {
    const a = moment(date)
    const b = moment(this.firstRederedDay)

    return a.diff(b, 'days')

  }

  calculateLength(start:string, end: string) {
    const a = moment(end)
    const b = moment(start)


    console.log(a.diff(b, 'days'))

    return a.diff(b, 'days') + 1

  }


  monthName(month:string) {
    return moment(month, 'YYYY-MM').format('MMM')
  }

  monthYear(month:string) {
    return moment(month, 'YYYY-MM').format('YYYY')
  }


  zoomIn() {
    this.daySize.next(100)
  }

  zoomOut() {
    this.daySize.next(20)
  }

}



// one day = 32px
// week = 7 days = 32 * 7 == 224px
// month = (days in month) * 32 px



