import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-risk-card',
  templateUrl: './risk-card.component.html',
  styleUrls: ['./risk-card.component.scss']
})
export class RiskCardComponent implements OnInit {

  risks$: Observable<any>
  newRisk: boolean

  showCommon:boolean

  constructor(private firestore: FirebaseService, private state: StateService) { }

  ngOnInit(): void {
    this.state.returnSidebarState()
    .subscribe((sidebar) => {

      // Get The project releated risks from firebas
      this.risks$ = this.firestore.getCollectionSnapshotPath(`projects/${sidebar.projectID}/risks`)
      //this.risks$.subscribe((res) => console.log(res))
    })
  }

}
