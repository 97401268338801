import { Component, OnInit } from "@angular/core";
import { articles } from "./articles";

@Component({
  selector: "app-budgeting",
  templateUrl: "./budgeting.component.html",
  styleUrls: ["./budgeting.component.scss"],
})
export class BudgetingComponent implements OnInit {
  articles = articles;

  selectData = [
    {value: 'rbk', desc: 'RBK-Mätning'},
    {value: 'fuktakerhetsbeskrivning', desc: 'Fuktsäkerhetsbeskrivning'},
  ]


  testnumber = 1234567

  currentMenuId = "menu1";

  measurements: number = 1;
  budget: any

  constructor() {}

  ngOnInit(): void {}

  createMeasurements() {
    let num = this.measurements;

    let measurements = [];

    for (let i = 0; i < num; i++) {
      measurements.push({
        name: `Mätning ${i + 1}`,
        mps: null,
        levelingScreed: false,
      });
    }

    this.budget = {};
    this.budget.measurements = measurements;
  }

  sumPriceMeasurement(measurement) {
    let sum = measurement.sum;
    let keys = sum.keys;
    let price = 0;

    for (let i = 0; i < sum.keys.length; i++) {
      price = price + sum[keys[i]] * this.articles[keys[i]].price;
    }

    return price;
  }

  totalPrice() {
    let total = 0;
    if (this.budget) {
      if (this.budget.measurements) {
        for (let i = 0; i < this.budget.measurements.length; i++) {
          let measurement = this.budget.measurements[i];
          if (measurement.sum) {
            total = total + this.sumPriceMeasurement(measurement);
          }
        }
      }
    }

    return total;
  }

  buildBlocks() {
    for (let i = 0; i < this.budget.measurements.length; i++) {
      let measurement = this.budget.measurements[i];

      measurement.rows = this.parseBlockRBK(measurement).rows;
      measurement.sum = this.parseBlockRBK(measurement).sum;
    }

    console.log(this.budget);
  }

  rowSum(rows) {
    let r = {};

    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];

      if (r[row.art]) {
        r[row.art] = r[row.art] + row.count;
      } else {
        r[row.art] = row.count;
      }
    }

    return { ...r, keys: Object.keys(r) };
  }

  parseBlockRBK(measurement: any) {
    let block = {
      rows: {
        fm013: 0,
        fm010: 0,
        fm002: 0,
      },
    };

    let drill = 0;
    let mount = 0;
    let read = 0;
    let doc = 0;

    if (measurement.mps < 6) {
      drill = 2;
      mount = 2;
      read = 2;
      doc = 2;

      let rows = [
        {
          desc: "Antal mätpunkter",
          unit: "st",
          count: measurement.mps,
          art: "fm010",
        },
        { desc: "Borrning", unit: "h", count: drill, art: "fm013" },
        { desc: "Montering", unit: "h", count: mount, art: "fm013" },
        { desc: "Avläsning", unit: "h", count: read, art: "fm013" },
        {
          desc: "Upprättande av Dokumentation",
          unit: "h",
          count: doc,
          art: "fm013",
        },
      ];

      let sum = this.rowSum(rows);
      console.log(sum);

      return { rows, sum };
    } else if (measurement.mps < 13) {
      drill = 3;
      mount = 2;
      read = 2;
      doc = 2;

      let rows = [
        {
          desc: "Antal mätpunkter",
          unit: "st",
          count: measurement.mps,
          art: "fm010",
        },
        { desc: "Borrning", unit: "h", count: drill, art: "fm013" },
        { desc: "Montering", unit: "h", count: mount, art: "fm013" },
        { desc: "Avläsning", unit: "h", count: read, art: "fm013" },
        {
          desc: "Upprättande av Dokumentation",
          unit: "h",
          count: doc,
          art: "fm013",
        },
      ];

      return { rows, sum: this.rowSum(rows) };
    } else if (measurement.mps < 17) {
      drill = 4;
      mount = 3;
      read = 3;
      doc = 3;

      let rows = [
        {
          desc: "Antal mätpunkter",
          unit: "st",
          count: measurement.mps,
          art: "fm010",
        },
        { desc: "Borrning", unit: "h", count: drill, art: "fm013" },
        { desc: "Montering", unit: "h", count: mount, art: "fm013" },
        { desc: "Avläsning", unit: "h", count: read, art: "fm013" },
        {
          desc: "Upprättande av Dokumentation",
          unit: "h",
          count: doc,
          art: "fm013",
        },
      ];

      return { rows, sum: this.rowSum(rows) };
    } else if (measurement.mps >= 17) {
      drill = 6;
      mount = 3;
      read = 3;
      doc = 3;

      let rows = [
        {
          desc: "Antal mätpunkter",
          unit: "st",
          count: measurement.mps,
          art: "fm010",
        },
        { desc: "Borrning", unit: "h", count: drill, art: "fm013" },
        { desc: "Montering", unit: "h", count: mount, art: "fm013" },
        { desc: "Avläsning", unit: "h", count: read, art: "fm013" },
        {
          desc: "Upprättande av Dokumentation",
          unit: "h",
          count: doc,
          art: "fm013",
        },
      ];

      return { rows, sum: this.rowSum(rows) };
    }
  }
}
