<ng-container *ngIf="!size">
  <div  class="flex flex-col rounded bg-white p-2">
    <app-user-block [size]="'full'" [rm]="rm" [user]="user"></app-user-block>
  </div>
</ng-container>

<ng-container *ngIf="size">
  <div  class="flex flex-row rounded">
    <app-user-block [size]="'sm'" [rm]="rm" [user]="user"></app-user-block>
  </div>
</ng-container>
