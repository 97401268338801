<div
  *ngIf="size == 'full'"
  (click)="select()"
  class="w-full flex hover:bg-blue-100 rounded m-1 p-1 cursor-pointer"
>
  <div class="w-12 flex">
    <img
      class="circle self-center"
      [src]="imageToShow"
      alt="Place image title"
      title="test"
      *ngIf="imageToShow; else noImageFound"
    />
    <ng-template #noImageFound>
      <div *ngIf="user" [class]="circleClass">
        <div 
          class="self-center w-full text-center text-sm font-medium text-gray-800"
        >
          {{ text() }}
        </div>
        <div class="item">

          <!-- above line will produce the rendering of 5 circles with the pulse animation and the aria-valuetext attribute set with "This item is actually loading..." -->
        </div>
      </div>
      <div *ngIf="!user">
        <ngx-skeleton-loader count="1" 
        appearance="circle"
        [theme]="{ 
          'border-radius': '100%',
          height: '40px',
          width: '40px',
          border: '1px solid white'
        }"
        ></ngx-skeleton-loader>
      </div>
    </ng-template>
  </div>
  <div *ngIf="user" class="w-full self-center ml-2">
    <span
      *ngIf="role"
      class="text-xs rounded bg-blue-50 border p-0.5 text-gray-800"
      >{{ user.role }}</span
    >
    <p>{{ user.displayName }}</p>
    <p class="text-xs text-blue-500">{{ user.jobTitle }}</p>
    <button
      (click)="removeParticipant()"
      *ngIf="rm"
      class="text-xs rounded p-0.5 bg-gray-500 hover:bg-red-600 text-white"
    >
      Ta bort
    </button>
  </div>
  <div *ngIf="!user" class="w-full self-center ml-2">
    <p><ngx-skeleton-loader count="2" 
      animation="pulse"
      [theme]="{ 
        margin: '4px'
      }"
      ></ngx-skeleton-loader></p>

  </div>
  
</div>

<div
  (mouseover)="show = true"
  (mouseleave)="show = false"
  *ngIf="size == 'sm'"
  (click)="select()"
  class="w-full flex"
>
  <div class="w-12 flex">
    <img
      class="circle self-center"
      [src]="imageToShow"
      alt="Place image title"
      title="test"
      *ngIf="imageToShow; else noImageFound"
    />
    <ng-template #noImageFound>
      <div *ngIf="user" [class]="circleClass">
        <div 
          class="self-center w-full text-center text-sm font-medium text-gray-800"
        >
          {{ text() }}
        </div>
        <div class="item">

          <!-- above line will produce the rendering of 5 circles with the pulse animation and the aria-valuetext attribute set with "This item is actually loading..." -->
        </div>
      </div>
      <div *ngIf="!user">
        <ngx-skeleton-loader count="1" 
        appearance="circle"
        [theme]="{ 
          'border-radius': '100%',
          height: '38px',
          width: '38px',
          margin: '0px',
          padding: '0px',
          border: '1px solid white'
        }"
        ></ngx-skeleton-loader>
      </div>
    </ng-template>
  </div>
</div>
<div
  *ngIf="show"
  class="text-xs absolute text-center text-gray-600 w-48 tooltip"
>
  {{ user.displayName }}
</div>
