import { Component, Input, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { StateService } from 'src/app/core/services/state.service';
import {environment} from '../../../../environments/environment'

@Component({
  selector: 'app-check-block',
  templateUrl: './check-block.component.html',
  styleUrls: ['./check-block.component.scss']
})
export class CheckBlockComponent implements OnInit {

  currentProject:any
  projectID:string

  checked: boolean

  @Input() checkpoint:any
  @Input() blockIndex:number
  @Input() checkIndex:number

  constructor(private stateService: StateService, private firebaseService: FirebaseService) { }

  ngOnInit(): void {

    this.stateService.returnSidebarState()
    .subscribe((state) => {
      this.projectID = state.projectID
      this.stateService.getBooking(state.projectID)
    .subscribe((res:any) => {
      this.checkStatus({...res})
    });
    })

    
  }


  // TODO: Comment what the *** this functions actually does

  checkStatus(project:any) {


    const currentProject:any = {...project}

    const create = () => {
      currentProject.checkBlocks = {
        [this.blockIndex]: {
          [this.checkIndex]: {
            checked: false
          }
        }
      }
    }


    if (currentProject.checkBlocks) {

      if (currentProject.checkBlocks[this.blockIndex]) {

        if (currentProject.checkBlocks[this.blockIndex][this.checkIndex]) {
          this.checked = currentProject.checkBlocks[this.blockIndex][this.checkIndex].checked
        } else {
          create()
        }

      } else {
        create()
      }

    } else {
      create()
    }

  }

  toggle() {
    const prop = `checkBlocks.${this.blockIndex}.${this.checkIndex}.checked`
    this.checked = !this.checked

    this.firebaseService.updateDocumentPath(`${environment.collections.projects}/${this.projectID}`, {
      [prop]: this.checked
    }).then(() => console.log('updated'))
  }

}
