import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  userForm = new FormGroup({
    id: new FormControl('', Validators.required),
    displayName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl(''),
    company: new FormControl('dry-it', Validators.required),
    country: new FormControl('se', Validators.required),
    city: new FormControl('', Validators.required),
  });

  user$: Observable<any>;
  user: any;

  companies = [
    { name: 'Raksystems Dry-IT', value: 'dry-it' },
  ];

  constructor(private stateService: StateService, private router: Router) {}

  ngOnInit(): void {
    // Load the current logged in user
    this.user$ = this.stateService.returnCurrentUser();
   const sub = this.user$.subscribe((user) => {

    // Check if the users profile has ben initiated
    // Load User data into the profile form    
     if (user.profileInit) this.router.navigateByUrl('/projects/dashboard')
      this.userForm.patchValue(user);
    });
  }

  onSubmit() {
    // Update the profile on submit.
    this.stateService.updateUser({...this.userForm.value, profileInit: true})
  }
}
