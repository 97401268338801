import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/core/services/state.service';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss']
})
export class AllProjectsComponent implements OnInit {

  projectScope:string = 'all'

  constructor(private router: Router, private stateService: StateService) { }

  ngOnInit(): void {
    this.stateService.hideSidebar()
    this.stateService.loadUsers()
  }

  openActivity(id:string) {
    this.router.navigateByUrl(`projects/project/${id}`)
  }

  createActivity() {
    this.router.navigateByUrl('projects/create/project')
  }

}
