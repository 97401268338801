import { Component, Input, OnInit } from '@angular/core';
import { GanttService } from 'src/app/core/services/gantt.service';
import * as moment from 'moment';


@Component({
  selector: 'app-gantt-ruler-weeks',
  templateUrl: './gantt-ruler-weeks.component.html',
  styleUrls: ['./gantt-ruler-weeks.component.scss']
})
export class GanttRulerWeeksComponent implements OnInit {

  @Input() daysToRender: number
  @Input() daySize: number
  @Input() startWeek: number
  @Input() firstMonth: any
  


  weeksToRender: number
  weeks = []

  width: string

  constructor(private gs: GanttService) { }

  ngOnInit(): void {

    this.gs.daySize.subscribe((size) => {
      this.width = (7 * size).toString() + 'px'
    })

    //console.log(this.firstMonth.month)
    //console.log('weeks to render = ' + (this.daysToRender / 7))

    

    this.weeksToRender = Math.round(this.daysToRender / 7)

    let currentDate = moment(this.firstMonth.month + '-01')

    for (let i = 0; i < this.weeksToRender; i++) {

      const week = currentDate.format('W')
      //console.log(week)
      this.weeks.push(week)
      currentDate = moment(currentDate).add(7, 'days')



  }

} 
}
