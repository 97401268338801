import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WrapperComponent } from './projects/components/wrapper/wrapper.component';

import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { LoginComponent } from './core/components/login/login.component';
import { ActivityComponent } from './projects/components/activity/activity.component';
import { MyProfileComponent } from './user/my-profile/my-profile.component';
import { CreateActivityComponent } from './projects/components/create-activity/create-activity.component';
import { MainComponent } from './projects/components/main/main.component';
import { DocumentEditorComponent } from './projects/components/document-editor/document-editor.component';
import { ProjectPlanningComponent } from './projects/components/project-planning/project-planning.component';
import { AllProjectsComponent } from './projects/components/all-projects/all-projects.component';
import { BudgetingComponent } from './projects/components/budgeting/budgeting.component';
import { CommonModule } from '@angular/common';
import { ClosedComponent } from './core/components/closed/closed.component';
import { CommonRisksComponent } from './projects/components/common-risks/common-risks.component';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['items']);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const open = (open: Boolean) => {
  if (open) return 'projects/dashboard';
  else return 'service'
}

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: open(true)},
  {path: 'login', component: LoginComponent},
  {path: 'common-risks', component: CommonRisksComponent},
  {path: 'service', component: ClosedComponent},
  {path: 'user/completemyprofile', component: MyProfileComponent, data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'projects', canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, component: WrapperComponent, children: [
    {path: 'dashboard', component: MainComponent},
    {path: 'allprojects', component: AllProjectsComponent},
    {path: 'project/:id', component: ActivityComponent},
    {path: 'document', component: DocumentEditorComponent},
    {path: 'projectplan', component: ProjectPlanningComponent},
    {path: 'budgeting', component: BudgetingComponent},
    {path: 'create/project', component: CreateActivityComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule, CommonModule]
})
export class AppRoutingModule { }
