<div [style.width]="width" class="bg-gray-50 border day">
    <!-- <div class="absolute text-gray-300">{{index}}</div> -->
<ng-container *ngIf="draw">
    <div class="h-full flex">
      <div  (mouseover)="attatchListner()" [style.margin-left]="margin" [style.background-color]="backGround" [style.width]="drawSize" class="absolute bg-blue-600 text-white h-6 rounded-full p-1 text-xs block-body self-center">
        <div class="w-full flex justify-items-center h-4">
            <div #dragleft class="w-6 self-center"><button class="float-left resize-block"><svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg></button></div>
            <div #move class="w-full grab self-center"><span class="font-bold uppercase">{{block.title}}</span> - ({{index}}) - {{block.startDate}} - {{block.stopDate}}</div>
            <div #dragright class="w-6 self-center"><button class="float-right resize-block self-center"><svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 self-center" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg></button></div>
        </div>
    </div>  
    </div>
    
</ng-container>
</div>
