
import { Action } from '@ngrx/store';

export const SHOW =    '[sidebar] show'
export const HIDE =      '[sidebar] hide'
export const UPDATE =      '[sidebar] update'

export class Show implements Action {
    readonly type = SHOW;
    constructor() {}
}

export class Hide implements Action {
    readonly type = HIDE;
    constructor() {}
}

export class Update implements Action {
    readonly type = UPDATE;
    constructor(public payload: any) {}
}

export type ProjectActions = 
Show |
Hide |
Update
